import { useEffect, useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { isAuthenticated } from './util/utils'

const PrivateRoutes = () => {
    let auth = window.localStorage.getItem("user")?true:false
    return(
        auth ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes