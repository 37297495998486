// eslint-disable-next-line
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import SiteLayout from "../components/layout/SiteLayout";

const ResearchPage = () => {
  return (
    <SiteLayout variant="withoptions">
      <div className="research">
        <div className="container">
          <div className="research__inner">
            <div className="research__top">
              <div className="researchbox">
                <div className="checkbox--boxy">
                  <input type="checkbox" name="trending" id="trending" />
                  <label htmlFor="trending">Trending</label>
                </div>
                <ul>
                  <li>
                    <small className="nametitle">
                      <span className="serial">1</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Another
                    </small>
                    <small className="success">+5.25%</small>
                  </li>
                  <li>
                    <small className="nametitle">
                      <span className="serial">2</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Ethereum
                    </small>
                    <small className="success">+5.25%</small>
                  </li>
                  <li>
                    <small className="nametitle">
                      <span className="serial">3</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Tether
                    </small>
                    <small className="success">+5.25%</small>
                  </li>
                </ul>
              </div>
              <div className="researchbox">
                <div className="checkbox--boxy">
                  <input type="checkbox" name="topgainer" id="topgainer" />
                  <label htmlFor="topgainer">Top Gainer</label>
                </div>
                <ul>
                  <li>
                    <small className="nametitle">
                      <span className="serial">1</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Another
                    </small>
                    <small className="success">+5.25%</small>
                  </li>
                  <li>
                    <small className="nametitle">
                      <span className="serial">2</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Ethereum
                    </small>
                    <small className="success">+5.25%</small>
                  </li>
                  <li>
                    <small className="nametitle">
                      <span className="serial">3</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Tether
                    </small>
                    <small className="success">+5.25%</small>
                  </li>
                </ul>
              </div>
              <div className="researchbox">
                <div className="checkbox--boxy">
                  <input type="checkbox" name="toploser" id="toploser" />
                  <label htmlFor="toploser">Top Loser</label>
                </div>
                <ul>
                  <li>
                    <small className="nametitle">
                      <span className="serial">1</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Another
                    </small>
                    <small className="danger">+5.25%</small>
                  </li>
                  <li>
                    <small className="nametitle">
                      <span className="serial">2</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Ethereum
                    </small>
                    <small className="danger">+5.25%</small>
                  </li>
                  <li>
                    <small className="nametitle">
                      <span className="serial">3</span>
                      <span className="image">
                        <img src="/icons/ethereum.svg" alt="icon" />
                      </span>
                      Tether
                    </small>
                    <small className="danger">+5.25%</small>
                  </li>
                </ul>
              </div>
            </div>
            <div className="research__bottom">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th className="text-left">Name</th>
                      <th className="text-left">Price</th>
                      <th className="text-center">1 hour</th>
                      <th className="text-center">1 days</th>
                      <th className="text-center">7 days</th>
                      <th className="text-center">Market cap</th>
                      <th className="text-center">Circulation supply</th>
                      <th className="text-right">Last 7 days</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          1
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/ethereum.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Ethereum</Link>
                        </p>
                        <span className="tiny-text">ETH</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-increment.png"
                            alt="increment"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          2
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/tether.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Tether</Link>
                        </p>
                        <span className="tiny-text">USDT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-decrement.png"
                            alt="decrement"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          3
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/another.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Another</Link>
                        </p>
                        <span className="tiny-text">ANT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-increment.png"
                            alt="increment"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          4
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/tether.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Tether</Link>
                        </p>
                        <span className="tiny-text">USDT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-decrement.png"
                            alt="decrement"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          5
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/ethereum.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Ethereum</Link>
                        </p>
                        <span className="tiny-text">ETH</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-increment.png"
                            alt="increment"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          6
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/tether.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Tether</Link>
                        </p>
                        <span className="tiny-text">USDT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-decrement.png"
                            alt="decrement"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          7
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/another.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Another</Link>
                        </p>
                        <span className="tiny-text">ANT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-increment.png"
                            alt="increment"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          8
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/tether.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Tether</Link>
                        </p>
                        <span className="tiny-text">USDT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-decrement.png"
                            alt="decrement"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          9
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/ethereum.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Ethereum</Link>
                        </p>
                        <span className="tiny-text">ETH</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-increment.png"
                            alt="increment"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          10
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/tether.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Tether</Link>
                        </p>
                        <span className="tiny-text">USDT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-decrement.png"
                            alt="decrement"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          11
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/another.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Another</Link>
                        </p>
                        <span className="tiny-text">ANT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-increment.png"
                            alt="increment"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="serial">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.1125 12.3396L8 10.5979L10.8875 12.3625L10.1313 9.06248L12.675 6.86248L9.32917 6.56456L8 3.44789L6.67084 6.54164L3.325 6.83956L5.86875 9.06248L5.1125 12.3396ZM8 12.7521L4.19584 15.0437C4.02778 15.1507 3.85209 15.1965 3.66875 15.1812C3.48542 15.1659 3.325 15.1048 3.1875 14.9979C3.05 14.8909 2.94306 14.7574 2.86667 14.5973C2.79028 14.4366 2.775 14.2569 2.82084 14.0583L3.82917 9.72706L0.46042 6.81664C0.307642 6.67914 0.212309 6.52239 0.17442 6.34639C0.13592 6.171 0.147226 5.99928 0.208337 5.83123C0.269448 5.66317 0.361115 5.52567 0.483337 5.41873C0.605559 5.31178 0.773614 5.24303 0.987503 5.21248L5.43334 4.82289L7.15209 0.743726C7.22848 0.560393 7.34703 0.422892 7.50775 0.331226C7.66787 0.239559 7.83195 0.193726 8 0.193726C8.16806 0.193726 8.33245 0.239559 8.49317 0.331226C8.65328 0.422892 8.77153 0.560393 8.84792 0.743726L10.5667 4.82289L15.0125 5.21248C15.2264 5.24303 15.3944 5.31178 15.5167 5.41873C15.6389 5.52567 15.7306 5.66317 15.7917 5.83123C15.8528 5.99928 15.8644 6.171 15.8265 6.34639C15.788 6.52239 15.6924 6.67914 15.5396 6.81664L12.1708 9.72706L13.1792 14.0583C13.225 14.2569 13.2097 14.4366 13.1333 14.5973C13.0569 14.7574 12.95 14.8909 12.8125 14.9979C12.675 15.1048 12.5146 15.1659 12.3313 15.1812C12.1479 15.1965 11.9722 15.1507 11.8042 15.0437L8 12.7521Z"
                              fill="url(#paint0_linear_5_11880)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_5_11880"
                                x1="0.1521"
                                y1="0.193726"
                                x2="13.8535"
                                y2="18.5506"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#9C37FD" />
                                <stop offset="1" stopColor="#15BFFD" />
                              </linearGradient>
                            </defs>
                          </svg>
                          12
                        </div>
                      </td>
                      <td>
                        <span className="icon">
                          <img src="/icons/tether.svg" alt="icon" />
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="name">
                          <Link to="/coinpage">Tether</Link>
                        </p>
                        <span className="tiny-text">USDT</span>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+14.02%</small>
                      </td>
                      <td className="text-center">
                        <small className="danger">-20.14%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">+30.24%</small>
                      </td>
                      <td className="text-center">
                        <small>$1205,145,745,741</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,124,953 USDT</small>
                      </td>
                      <td className="text-right">
                        <div className="incdec">
                          <img
                            src="/images/chart-decrement.png"
                            alt="decrement"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <br />
              <div className="pagination text-center">
                <ul>
                  {/* <li>
                  <Link to="/transactions" className="prev">
                    <FiChevronLeft />
                  </Link>
                </li> */}
                  <li>
                    <Link to="/transactions">1</Link>
                  </li>
                  <li>
                    <Link to="/transactions">2</Link>
                  </li>
                  <li>
                    <Link to="/transactions">3</Link>
                  </li>
                  <li>
                    <Link to="/transactions" className="next">
                      <FiChevronRight />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default ResearchPage;
