import { toast } from "react-toastify"

const moment = require('moment');

export const NOTIFICATION_STATUS = {
  UNREAD: "N_UnRead",
  READ: "N_Read",
  DELETED: "N_Deleted"
}
export const OFFER_STATUS = {
  CREATED: 1,
  DEPOSITED: 2,
  COMPLETED: 3,
  CANCELLED: 4
}

export const CHANNEL = {
  CONVERSATION: "CONVERSATION"
}

export const NOTIFICATION_TYPE = {
  OFFER: "OFFER",
}
export const OFFER_NOTIFICATION_TYPE = {
  CREATED: 1,
  DEPOSITED: 2,
  COMPLETED: 3,
  CANCELLED: 4
}
export const getTotalSoldAmount = (records: any) => {
    if(!records) return 0
    const totalAmount = records.reduce((acc: any, record: any) => {
        return acc + parseInt(record.amount);
    }, 0); 
    return totalAmount
}

export const copyText = async (copytext: string, toastText: any) => {
  try {
    await navigator.clipboard.writeText(copytext);
    toast.success(toastText, { autoClose: 1200 });
    console.log(toastText);
  } catch (error) {
    console.error('Failed to copy to clipboard: ', error);
  }
};


export const getActiveStatus = (lastActive: any) => {
  const now = moment();
  const diffInMs = now.diff(lastActive);

  // If last active within 3 minutes, show as "Active now"
  if (diffInMs <= 3 * 60 * 1000) {
    return 'Active now';
  }

  // Otherwise, format time elapsed in minutes, hours, or days
  const diffInMin = moment.duration(diffInMs).asMinutes();
  const diffInHr = moment.duration(diffInMs).asHours();
  const diffInDay = moment.duration(diffInMs).asDays();

  if (diffInMin < 60) {
    return `Last seen ${Math.floor(diffInMin)} minutes ago`;
  } else if (diffInHr < 24) {
    return `Last seen ${Math.floor(diffInHr)} hours ago`;
  } else {
    return `Last seen ${Math.floor(diffInDay)} days ago`;
  }
}

export const getSmsTime = (data: any) => {
  const now = moment();
  const diffInMs = now.diff(data);
  if (diffInMs <= 1 * 60 * 1000) {
    return 'Just now';
  }

  // Otherwise, format time elapsed in minutes, hours, or days
  const diffInMin = moment.duration(diffInMs).asMinutes();
  const diffInHr = moment.duration(diffInMs).asHours();
  const diffInDay = moment.duration(diffInMs).asDays();

  if (diffInMin < 60) {
    return `${Math.floor(diffInMin)} minutes ago`;
  } else if (diffInHr < 24) {
    return `${Math.floor(diffInHr)} hours ago`;
  } else {
    return `${Math.floor(diffInDay)} days ago`;
  }
}
