import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <div className="calltoaction">
      <span className="blobbg calltoaction__blob"></span>
      <div className="container">
        <div className="calltoaction__inner">
          <h2>Ready to get started?</h2>
          <p>
            Explore thousands of offers to buy and sell Cryptos to kickstart
            your trading journey.
          </p>
          <Link to={"/createoffer"}>
          <button className="btn">Create your offer</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
