export type TSectionTitleProps = {
  title: string;
  subtitle: string;
};

const SectionTititle = ({ title, subtitle }: TSectionTitleProps) => {
  return (
    <div className="sectiontitle">
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
};

export default SectionTititle;
