import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createStore, applyMiddleware, Store, compose } from "redux"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import reportWebVitals from "./reportWebVitals";
import reducer from "./store/reducer"
import { DispatchType, UserAction, UserState } from "./type";
import { RecoilRoot } from "recoil";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store: Store<UserState, UserAction> & {
  dispatch: DispatchType
} = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
