import axios from "axios";
import { baseURL } from "../util/utils";


class AuthService {
  login(email: string, password: string) {
    return axios
      .post(baseURL + "/api/auth", {
        email,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(name: string, email: string, password: string) {
    return axios.post(baseURL + "/api/users", {
      name,
      email,
      password
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();