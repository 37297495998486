import { useState } from "react";
import { FiMail, FiUser, FiLock } from "react-icons/fi";
import SiteLayout from "../components/layout/SiteLayout";
import RegisterImage from "../assets/images/register-image.png";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loading from "../components/loading";
import axios from "axios";
import { baseURL } from "../util/utils";

const Register = () => {

  type Values = {
    name: string,
    email: string,
    password: string,
  }

  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [isRegistering, setIsRegistering] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsRegistering(true)
    axios.post(`${baseURL}/api/users/register`, { name, email, password })
      .then((response) => {
        setIsRegistering(false)
        toast.success('Successfully Registered!', { autoClose: 1500, position: "top-center" });
        setTimeout(() => {
          window.location.href = `/varify?user=${response.data?.user}`
        }, 1200);
      })
      .catch(err => {
        setIsRegistering(false)
        console.log(err.response?.data)
        toast.error(err.response?.data.message, { autoClose: 1500 });
      })
  }

  return (
    <SiteLayout variant="onlylogo" noFooter>
      <div className="register">
        <div className="container">
          <div className="register__inner">
            <div className="register__content">
              <h4 className="register__title">Create your account</h4>
              <form className="register__form" method="post" onSubmit={(e) => handleSubmit(e)}>
                <div className="register__field">
                  <label htmlFor="">Your Username</label>
                  <div className="inputbox">
                    <FiUser />
                    <input type="text" placeholder="Enter user name" required value={name} name="name" onChange={e => setName(e.target.value)} />
                  </div>
                </div>
                <div className="register__field">
                  <label htmlFor="">Your E-mail</label>
                  <div className="inputbox">
                    <FiMail />
                    <input type="email" placeholder="Enter user e-mail" required value={email} name="email" onChange={e => setEmail(e.target.value)} />
                  </div>
                </div>
                <div className="register__field">
                  <label htmlFor="">Password</label>
                  <div className="inputbox">
                    <FiLock />
                    <input type="password" placeholder="Enter Password" required value={password} name="password" onChange={e => setPassword(e.target.value)} />
                  </div>
                </div>
                <div className="register__buttons">
                  <button className="btn" type="submit">
                    {
                      isRegistering ?
                        <>
                          <Loading width={25} /> Registering...
                        </> :
                        "Register"
                    }
                  </button>
                  <Link to={'/'}>
                    <button
                      className="btn btn--outline"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
                <p style={{ marginTop: '15px' }} >Already have an account ?  <Link to={"/login"} style={{ color: '#7c3aed', textDecoration: 'underline' }} >Go to Login </Link> </p>
              </form>
            </div>
            <div className="register__image">
              <img src={RegisterImage} alt="register" />
            </div>
          </div>
        </div>
        <span className="blobbg register__blob"></span>
      </div>
    </SiteLayout>
  );
};

export default Register;
