import React, { useEffect, useState } from "react";
import { FiExternalLink, FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import SiteLayout from "../components/layout/SiteLayout";
import { useParams } from 'react-router-dom'
import axios from "axios";
import authHeader from "../services/auth-header";
import { toast, useToast } from "react-toastify";
import { baseURL, getLastActiveTime } from "../util/utils";
import { useRecoilState } from "recoil"
import { userState } from "../state/state";
import Loading from "../components/loading";
const OfferDetails = () => {
  const [user, setUser] = useRecoilState(userState)

  const [payToken, setPayToken] = useState<number>(0) //set minAmouont
  const { offerId } = useParams();
  const [offer, setOffer] = useState<any>({});
  const [completing, setCompleting] = useState(false)
  const fetchOffers = () => {
    return axios.get(`${baseURL}/api/offers/getOfferById/${offerId}`, { headers: authHeader() })
      .then((resp) => {
        console.log("hi offer ", resp.data.offer)
        setOffer(resp.data.offer);
        var currentOffer = resp.data.offer
        setPayToken(currentOffer.minAmount)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchOffers();
  }, []);

  const completeTrade = () => {
    toast.info("Trade confirming ...")
    setCompleting(true)
    axios.post(`${baseURL}/api/offers/trade`, {
      offerId: offer.offerID,
      offerMID: offer._id
    }, { headers: authHeader() })
      .then((resp) => {
        toast.success(resp.data.message, { autoClose: 3000, position: "top-center" })
        setCompleting(false)
        fetchOffers()
      })
      .catch(err => {
        setCompleting(false)
        toast.error(err?.response?.data?.message, { autoClose: 5000 })
      })
  }
  return (
    <SiteLayout variant="withoptions">
      <div className="confirmbuy">
        <div className="container container--small">
          <> {console.log("offer is >>>>>", offer)} </>
          <div className="confirmbuy__inner">
            {
              (offer.offerStatus == 1) &&
              <>
                <h2 className="font-display text-center">Pending offer</h2>
                <br />
                <div className="buyform" >
                  <h4 className="text-center">We have received buyer deposit for a this offer. Would you like to proceed and confirm this trade? </h4>
                  <div className="buyform__formfields">
                    <div className="buyform__field submitbtn text-center">
                      {
                        completing ?
                          <div className="text-center" >
                            <Loading width={50} />
                            <p> Please wait ... </p>
                          </div> :
                          <button className="btn" onClick={e => completeTrade()} > Confirm </button>
                      }
                    </div>
                  </div>
                </div>
              </>
            }         {
              (offer.offerStatus == 2) &&
              <>
                <h2 className="font-display text-center">Offer Completed </h2>
                <br />
                <div className="buyform" >
                  <h4 className="text-center">This offer has been successfully completed. </h4>
                  <div className="buyform__formfields">
                    <div className="buyform__field submitbtn text-center">
                      <Link to={"/createOffer"}>
                        <button className="btn"  > Create a new Offer </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            }

            <br />
            <div className="confirmbuy__about">
              <div className="aboutoffer">
                <h5>About This offer</h5>
                <div className="aboutoffer__box">
                  <div className="aboutoffer__sellerrate">
                    <h6 className="title">Seller rate</h6>
                    <h5>
                      0.03 ETH{" "}
                      <span className="success">0.11% above market</span>
                    </h5>
                  </div>
                  <div className="aboutoffer__buylimits">
                    <h6 className="title">Buy limits</h6>
                    <h5>
                      <span>Min</span> -{offer.minAmount} {offer.buyToken?.symbol}
                    </h5>
                    <h5>
                      <span>Min</span> -{offer.maxAmount} {offer.sellToken?.symbol}
                    </h5>
                  </div>
                  <div className="aboutoffer__limitfee">
                    <div className="aboutoffer__limit">
                      <h6 className="title">Trade time limit</h6>
                      <h5> {offer.timeLimit} min </h5>
                    </div>
                    <div className="aboutoffer__fee">
                      <h6 className="title">NOM fee</h6>
                      <h5>1.03%</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutbuyer">
                <h5>About this Seller</h5>
                <div className="aboutbuyer__box">
                  <div className="aboutbuyer__inner">
                    <div className="aboutbuyer__top">
                      <div className="image">
                        <img
                          src={`${baseURL}/${offer.user?.pp}`}
                          alt=""
                          className="aboutbuyer__image"
                        />
                      </div>
                      <div className="content">
                        <h5>
                          {offer.user?.name}
                          <Link to="/profile">
                            <FiExternalLink />
                          </Link>
                        </h5>
                        <small>{getLastActiveTime(offer.user?.lastSeen)} </small>
                      </div>
                    </div>
                    <div className="aboutbuyer__content">
                      <div className="aboutbuyer__feedbacks">
                        <div className="aboutbuyer__feedback">
                          <div className="aboutbuyer__feedback-icon">
                            <FiThumbsUp />
                          </div>
                          <h5>+{offer.user?.upVote} </h5>
                          <small>Positive feedback</small>
                        </div>
                        <div className="aboutbuyer__feedback">
                          <div className="aboutbuyer__feedback-icon">
                            <FiThumbsDown />
                          </div>
                          <h5>-{offer.user?.downVote} </h5>
                          <small>Negative feedback</small>
                        </div>
                      </div>
                    </div>
                    <div className="aboutbuyer__bottom">
                      <p>Average trade speed</p>
                      <h5>Instant</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <h5>Offer terms</h5>
            {
              offer.offerTerms ?
                <div className="confirmbuy__offerterms">
                  <p> {offer.offerTerms} </p>
                </div> :
                <p> Seller does't included any terms !</p>
            }
            <br />
            <div className="text-right">
              <button className="btn">Report</button>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default OfferDetails;
