import axios from 'axios';

import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { FiCheckCircle, FiWatch, FiX } from "react-icons/fi";
import { toast } from 'react-toastify';
import SiteLayout from "../components/layout/SiteLayout";
import Loading from '../components/loading';
import authHeader from '../services/auth-header';
import { CHANNEL, getActiveStatus, getSmsTime, OFFER_STATUS } from '../util/constant';
import { baseURL, frontendSocket, getSuperRandomString, getUserFromToken } from '../util/utils';

import {
  FiThumbsDown,
  FiThumbsUp,
} from "react-icons/fi";
import {
  FaThumbsUp,
  FaThumbsDown,
} from "react-icons/fa";
import { BiFileBlank, BiVideoPlus } from 'react-icons/bi'
import { CiSearch } from 'react-icons/ci'
import { IoMdCall } from 'react-icons/io'
import { TbReportSearch, TbReportMoney } from 'react-icons/tb'
import { ImAttachment } from 'react-icons/im'
import { BsMic, BsEmojiSmile } from 'react-icons/bs'
import { AiOutlineSend } from 'react-icons/ai'
import { useRecoilState } from 'recoil';
import { chatHistoryState } from '../state/state';


const TradeStart = () => {
  const [offer, setOffer] = useState<any>()
  const [order, setOrder] = useState<any>()
  const [isSeller, setIsSeller] = useState(false)
  const [loading, setLoading] = useState(true)
  const [buyer, setBuyer] = useState<any>()
  const [completing, setCompleting] = useState(false)
  const [index, setIndex] = useState<any>()
  const [feedback, setFeedback] = useState<any>()
  const [feedbackText, setFeedbackText] = useState<any>()
  const [vote, setVote] = useState<any>(1)
  const [submitting, setSubmitting] = useState<any>(false)
  const [SMSText, setSMSText] = useState<any>()
  const [getChatHistoryState, setChatHistoryState] = useRecoilState(chatHistoryState)

  useEffect(() => {
    var token = window.localStorage.getItem("user")
    var decoded: any = jwtDecode(`${token}`)
    var conversationChannel = frontendSocket.subscribe(CHANNEL.CONVERSATION);

    conversationChannel.bind("client-orderCNV", function (data: any) {
      if (data.recipient == decoded.user.id) {
        setChatHistoryState(prevstate => ({ chatHistory: [...prevstate.chatHistory, data.message] }))
        scrollToBottom()
      } else {
        console.log("This notification not for you ")
      }
    })
    fetchOffer();
    return () => {
      conversationChannel.unbind("client-orderCNV");
      frontendSocket.unsubscribe(CHANNEL.CONVERSATION);
    };
  }, []);


  const sendSms = async (e: any) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const offerID = params.get("offerID");
    var user: any = getUserFromToken()
    const orderID: any = params.get("index");

    var conversationChannel = frontendSocket.subscribe(CHANNEL.CONVERSATION);
    // var sms = { offerID, orderID, recipientID: getOpponent(), message: SMSText };

    var socketSMS = {
      offerID: offerID,
      orderID: orderID,
      recipientID: getRecipient(orderID, user)._id,
      sender: user.user.id,
      message: SMSText,
      createdAt: Date.now()
    }
    console.log("Socket SMS", socketSMS);

    setChatHistoryState(prevstate => ({ chatHistory: [...prevstate.chatHistory, socketSMS] }))
    var sentSMStx = await axios.post(`${baseURL}/api/chat/create`, socketSMS, { headers: authHeader() });
    setSMSText("");
    requestAnimationFrame(() => {
      scrollToBottom();
    });
  };
  const getRecipient = (orderID: any, user: any) => {
    if (offer.soldRecord[orderID].userID == user.user.id) {
      return offer.user
    } else {
      return buyer
    }
  }
  function scrollToBottom() {
    let scrollCount = 0;
    const chatContainer: any = document.getElementById('chat_history');
    const lastMessage = chatContainer.lastElementChild;

    const scrollInterval = setInterval(() => {
      lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      scrollCount++;

      if (scrollCount === 5) {
        clearInterval(scrollInterval);
      }
    }, 100);

    // Stop event propagation to prevent scrolling up the whole page
    lastMessage.addEventListener('wheel', (e: any) => {
      e.stopPropagation();
    });
  }

  const fetchOffer = async () => {
    const params = new URLSearchParams(window.location.search);
    const offerID = params.get("offerID");
    const indexNumber = params.get("index");
    var chatHistory = await axios.post(`${baseURL}/api/chat/history`, { offerID, orderID: indexNumber }, { headers: authHeader() })
    setChatHistoryState({ chatHistory: [...chatHistory.data] })

    setIndex(indexNumber)
    return axios.get(`${baseURL}/api/offers/getOfferById/${offerID}`, { headers: authHeader() })
      .then(async (resp) => {
        setOffer(resp.data.offer);
        var currentOffer = resp.data.offer
        setOffer(currentOffer)
        var currentOrder = currentOffer.soldRecord[parseInt(`${indexNumber}`)]
        setOrder(currentOrder)

        if (currentOrder?.status == OFFER_STATUS.COMPLETED) {
          var fb = await axios.post(`${baseURL}/api/feedback/get-feedback`, { offerID: offerID, orderIndex: indexNumber }, { headers: authHeader() })
          setFeedback(fb.data)
        }
        var token = window.localStorage.getItem("user")
        var decoded: any = jwtDecode(`${token}`)

        var buyer = await axios.get(`${baseURL}/api/users/user/${currentOrder?.userID}`, { headers: authHeader() })
        setBuyer(buyer.data)
        if (currentOrder?.userID == decoded.user.id) {
          setIsSeller(false)
        } else {
          setIsSeller(true)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const isMe = (uid: any) => {
    var user: any = getUserFromToken()
    if (user?.user?.id == uid) {
      return true
    } else {
      return false
    }
  }

  const completeTrade = () => {
    const params = new URLSearchParams(window.location.search);
    const indexNumber = params.get("index");
    toast(
      <div style={{ display: "flex" }} >
        <div>
          <Loading width={30} />
        </div>
        <div> Please wait, your Token Withdraw confirming ... </div>
      </div>,
      { autoClose: false, position: 'top-center', draggable: false, progress: (false).toString() }
    )
    setCompleting(true)
    var obj = {
      offerId: offer?.offerID,
      offerMID: offer?._id,
      orderID: indexNumber
    }

    axios.post(`${baseURL}/api/offers/trade`, obj, { headers: authHeader() })
      .then((resp) => {
        toast.success(resp.data.message, { autoClose: 30000, position: "top-center" })
        toast.dismiss()
        setCompleting(false)
        fetchOffer()
      })
      .catch(err => {
        setCompleting(false)
        toast.dismiss()
        toast.error(err?.response?.data?.message, { autoClose: 5000 })
      })
  }
  
  const cancelTrade = () => {
    const params = new URLSearchParams(window.location.search);
    const indexNumber = params.get("index");
    toast(
      <div style={{ display: "flex" }} >
        <div>
          <Loading width={30} />
        </div>
        <div> Please wait ,Order cancelling... </div>
      </div>,
      { autoClose: false, position: 'top-center', draggable: false, progress: (false).toString() }
    )
    setCompleting(true)
    var obj = {
      offerId: offer?.offerID,
      offerMID: offer?._id,
      orderID: indexNumber
    }

    axios.post(`${baseURL}/api/offers/cancel`, obj, { headers: authHeader() })
      .then((resp) => {
        toast.success(resp.data.message, { autoClose: 30000, position: "top-center" })
        toast.dismiss()
        setCompleting(false)
        fetchOffer()
      })
      .catch(err => {
        setCompleting(false)
        toast.dismiss()
        toast.error(err?.response?.data?.message, { autoClose: 5000 })
      })
  }
  const feedbackHandler = async () => {
    if (!feedbackText) {
      return toast.error("Please leave a comment ", { autoClose: 1500, position: 'top-center' })
    }
    setSubmitting(true)
    var fd = {
      buyerID: buyer?._id,
      sellerID: offer?.user._id,
      offerID: offer._id,
      feedbackID: feedback?._id,
      orderIndex: index,
      feedbackFrom: isSeller ? "seller" : "buyer",
      vote: vote,
      feedback: feedbackText,
    }
    console.log("Feedback  is >>>>>", fd)
    var doneFeedback = await axios.post(`${baseURL}/api/feedback/do-feedback`, fd, { headers: authHeader() })
    fetchOffer()
    setSubmitting(false)
  }
  const requestForApprove = async () => {
    const params = new URLSearchParams(window.location.search);
    const offerID = params.get("offerID");
    var user: any = getUserFromToken()
    const orderID: any = params.get("index");

    var conversationChannel = frontendSocket.subscribe(CHANNEL.CONVERSATION);
    // var sms = { offerID, orderID, recipientID: getOpponent(), message: SMSText };

    var socketSMS = {
      offerID: offerID,
      orderID: orderID,
      recipientID: getRecipient(orderID, user)._id,
      sender: user.user.id,
      message: "Hi, it's great to have had the opportunity to assist you! If you are satisfied with the service provided, please feel free to mark the order as completed. 😊",
      createdAt: Date.now()
    }
    console.log("Socket SMS", socketSMS);

    setChatHistoryState(prevstate => ({ chatHistory: [...prevstate.chatHistory, socketSMS] }))
    var sentSMStx = await axios.post(`${baseURL}/api/chat/create`, socketSMS, { headers: authHeader() });
    setSMSText("");
    requestAnimationFrame(() => {
      scrollToBottom();
    });
  }
  return (
    <SiteLayout variant="withoptions">
      <div className="tradestart">
        <div className="container">
          <div className="tradestart__inner">
            <div className="tradestart__left">
              {
                loading &&
                <div className='text-center'>
                  <Loading width={40} />
                  <p>Please wait ...</p>
                </div>
              }
              {
                !loading &&
                <>
                  {
                    order?.status == OFFER_STATUS.DEPOSITED &&
                    <>
                      <h1 onClick={e => console.log(getChatHistoryState.chatHistory)} >Trade Started</h1>
                      {
                        isSeller ?
                          <div className="tradestart__info text-center">
                            <p>
                              <FiWatch />
                              {order?.amount * offer?.price} {offer?.buyToken?.symbol}  will be added to your Wallet
                            </p>
                            <div className="tradestart__boxes">
                              <div className="tradestart__box">
                                You Paid <img src={`${baseURL}/${offer?.sellToken?.logo}`} />
                              </div>
                              <div className="tradestart__box">
                                You will receive <img src={`${baseURL}/${offer?.buyToken?.logo}`} alt="icon" />
                              </div>
                            </div>
                          </div> :
                          <div className="tradestart__info text-center">
                            <p>
                              <FiWatch />
                              {order?.amount} {offer?.sellToken?.symbol}  will be added to your Wallet
                            </p>
                            <div className="tradestart__boxes">
                              <div className="tradestart__box">
                                You Paid <img src={`${baseURL}/${offer?.buyToken?.logo}`} />
                              </div>
                              <div className="tradestart__box">
                                You will receive <img src={`${baseURL}/${offer?.sellToken?.logo}`} alt="icon" />
                              </div>
                            </div>
                          </div>
                      }

                      <br />
                      {
                        isSeller ?
                          <div className="tradestart__actions">
                            {
                              completing ?
                                <button className="btn btn--outline">
                                  <Loading width={30} /> <span> Please Wait ... </span>
                                </button> :
                                // <button onClick={e => completeTrade()} className="btn btn--outline">
                                //   Withdraw your {offer?.buyToken?.name} &nbsp; <FiCheckCircle />
                                // </button>

                                <button onClick={e => completeTrade()} className="btn btn--outline">
                                  Approve Order &nbsp; <FiCheckCircle />
                                </button>
                            }
                            <button onClick={e=>cancelTrade()} className="btn btn--outline">
                              Cancel trade &nbsp; <FiX />
                            </button>
                          </div> :
                          <div className="tradestart__actions">
                            {/* {
                              completing ?
                                <button className="btn btn--outline">
                                  <Loading width={40} /> <span> Please Wait ... </span>
                                </button> :
                                <button onClick={e => completeTrade()} className="btn btn--outline">
                                  Withdraw your {offer?.sellToken?.name} &nbsp; <FiCheckCircle />
                                </button>
                            } */}

                            <button onClick={e => requestForApprove()} className="btn btn--outline">
                              Request for Approve &nbsp; <FiCheckCircle />
                            </button>
                            <button onClick={e=>cancelTrade()} className="btn btn--outline">
                              Cancel trade &nbsp; <FiX />
                            </button>
                          </div>
                      }
                      <br />
                      <div className="tradestart__terms">
                        <h5>Please follow the seller’s instruction</h5>
                        <div className="tradestart__termsinner">
                          <p> {offer?.offerTerms ? offer.offerTerms : "Seller doesn't included any terms !"} </p>
                        </div>
                        <br />
                      </div>
                    </>
                  }
                  {
                    order?.status == OFFER_STATUS.COMPLETED &&
                    <>
                      <>
                        {
                          isSeller ?
                            <div className={`text-center ${feedback?.sellerFeedback.done && "tradestart__info"} `}  >
                              <h1 className='text-center' >Trade Completed </h1>
                              <p>
                                <FiWatch />
                                {order?.amount * offer?.price} {offer?.buyToken?.symbol}   added to your Wallet
                              </p>
                              <div className="tradestart__boxes">
                                <div className="tradestart__box">
                                  You Paid <img src={`${baseURL}/${offer?.sellToken?.logo}`} />
                                </div>
                                <div className="tradestart__box">
                                  You will receive <img src={`${baseURL}/${offer?.buyToken?.logo}`} alt="icon" />
                                </div>
                              </div>
                              {
                                (feedback != null) &&
                                <>
                                  <br />
                                  {
                                    feedback?.sellerFeedback.done ?
                                      <>
                                        <h6 className='text-left' >Feedback for {buyer?.name} </h6>
                                        <div className="profilefeedback__feedback">
                                          <div className="feedback" style={{ padding: '0px' }} >
                                            <div className="feedback__usercontent">
                                              <p> {offer?.user?.name} </p>
                                              <small>Jun 17, 2022</small>
                                              {
                                                (feedback?.sellerFeedback.vote == 1) ?
                                                  <div className="feedback__type">
                                                    <FaThumbsUp /> Positive
                                                  </div> :
                                                  <div className="feedback__type">
                                                    <FaThumbsDown /> Nagetive
                                                  </div>
                                              }
                                            </div>
                                            <p className="feedback__content text-left">
                                              {feedback?.sellerFeedback.feedback}
                                            </p>
                                          </div>
                                        </div>
                                      </> :
                                      <div className='' >
                                        <br />
                                        <h5>Feedback for {buyer?.name} </h5>
                                        <div className="inputbox">
                                          <textarea placeholder="Leave your feedback here ..." name="feedback" value={feedbackText} onChange={e => setFeedbackText(e.target.value)} />
                                          <br />
                                          <button className="btn btn--outline" > <div className='do_vote' >
                                            {
                                              vote == 1 ?
                                                <span>
                                                  <FaThumbsUp />
                                                </span>
                                                :
                                                <span title='Positive' onClick={e => setVote(1)} >
                                                  <FiThumbsUp />
                                                </span>
                                            }
                                            {
                                              vote == 0 ?
                                                <span  >
                                                  <FaThumbsDown />
                                                </span>
                                                :
                                                <span onClick={e => setVote(0)}>
                                                  <FiThumbsDown />
                                                </span>
                                            }
                                          </div>
                                          </button>
                                        </div><br />
                                        <div className='text-right'>
                                          {
                                            submitting ?
                                              <div className='text-center' >
                                                <Loading width={35} />
                                                <p> Please Wait  </p>
                                              </div> :
                                              <button className='btn btn--outline' onClick={e => feedbackHandler()} > Submit </button>

                                          }
                                        </div>
                                      </div>
                                  }
                                  <br />
                                  {
                                    feedback?.buyerFeedback.done ?
                                      <>
                                        <h6 className='text-left'> {buyer?.name}'s  Feedback for you  </h6>
                                        <div className="profilefeedback__feedback">
                                          <div className="feedback" style={{ padding: '0px' }} >
                                            <div className="feedback__usercontent">
                                              <p> {buyer?.name} </p>
                                              <small>Jun 17, 2022</small>
                                              {
                                                (feedback?.buyerFeedback.vote == 1) ?
                                                  <div className="feedback__type">
                                                    <FaThumbsUp /> Positive
                                                  </div> :
                                                  <div className="feedback__type">
                                                    <FaThumbsDown /> Nagetive
                                                  </div>
                                              }
                                            </div>
                                            <p className="feedback__content text-left ">
                                              {feedback?.buyerFeedback.feedback}
                                            </p>
                                          </div>
                                        </div>
                                      </> :

                                      <div className='text-center'>
                                        <p><BiFileBlank size={30} /></p>
                                        <p className='text-center'>  {buyer?.name} not  given any Feedback yet</p>
                                      </div>
                                  }
                                </>
                              }
                            </div> :
                            <div className={`text-center ${feedback?.buyerFeedback.done && "tradestart__info"} `} >
                              <p>
                                <FiWatch />
                                {order?.amount} {offer?.sellToken?.symbol} added to your Wallet
                              </p>
                              <div className="tradestart__boxes">
                                <div className="tradestart__box">
                                  You Paid <img src={`${baseURL}/${offer?.buyToken?.logo}`} />
                                </div>
                                <div className="tradestart__box">
                                  You will received <img src={`${baseURL}/${offer?.sellToken?.logo}`} alt="icon" />
                                </div>
                              </div>
                              <br />
                              {
                                (feedback != null) &&
                                <>
                                  {
                                    feedback?.buyerFeedback.done ?
                                      <>
                                        <h6>Your Feedback for {offer?.user.name} </h6>
                                        <div className="profilefeedback__feedback">
                                          <div className="feedback" style={{ padding: '0px' }} >
                                            <div className="feedback__usercontent">
                                              <p> {buyer?.name} </p>
                                              <small>Jun 17, 2022</small>
                                              {
                                                (feedback?.buyerFeedback.vote == 1) ?
                                                  <div className="feedback__type">
                                                    <FaThumbsUp /> Positive
                                                  </div> :
                                                  <div className="feedback__type">
                                                    <FaThumbsDown /> Nagetive
                                                  </div>
                                              }
                                            </div>
                                            <p className="feedback__content text-left">
                                              {
                                                feedback?.buyerFeedback.feedback
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </> :
                                      <div className='tradestart__info' >
                                        <h5>Feedback for {isSeller ? buyer.name : offer?.user.name} </h5>
                                        <div className="inputbox">
                                          <textarea placeholder="Leave your feedback here ..." name="feedback" value={feedbackText} onChange={e => setFeedbackText(e.target.value)} />
                                          <br />
                                          <button className="btn btn--outline" > <div className='do_vote' >
                                            {
                                              vote == 1 ?
                                                <span>
                                                  <FaThumbsUp />
                                                </span>
                                                :
                                                <span title='Positive' onClick={e => setVote(1)} >
                                                  <FiThumbsUp />
                                                </span>
                                            }
                                            {
                                              vote == 0 ?
                                                <span  >
                                                  <FaThumbsDown />
                                                </span>
                                                :
                                                <span onClick={e => setVote(0)}>
                                                  <FiThumbsDown />
                                                </span>
                                            }
                                          </div>
                                          </button>
                                        </div><br />
                                        <div className='text-right'>
                                          {
                                            submitting ?
                                              <div>
                                                <Loading width={35} />
                                                <p>Please wait ...</p>
                                              </div>
                                              :
                                              <button className='btn btn--outline' onClick={e => feedbackHandler()} >Submit</button>
                                          }
                                        </div>
                                      </div>
                                  }
                                  <>
                                  </>
                                  <br />
                                  {
                                    (feedback?.sellerFeedback.done) ?
                                      <>
                                        <h6 className='text-left'> {offer?.user?.name}'s  Feedback [ Seller ] for you </h6>
                                        <div className="profilefeedback__feedback">
                                          <div className="feedback" style={{ padding: '0px' }} >
                                            <div className="feedback__usercontent">
                                              <p> {offer?.user?.name} </p>
                                              <small>Jun 17, 2022</small>
                                              {
                                                (feedback?.sellerFeedback.vote == 1) ?
                                                  <div className="feedback__type">
                                                    <FaThumbsUp /> Positive
                                                  </div> :
                                                  <div className="feedback__type">
                                                    <FaThumbsDown /> Nagetive
                                                  </div>
                                              }
                                            </div>
                                            <p className="feedback__content text-left ">
                                              {feedback?.sellerFeedback.feedback}
                                            </p>
                                          </div>
                                        </div>
                                      </> :
                                      <div className='text-center'>
                                        <p><BiFileBlank size={30} /></p>
                                        <p className='text-center'>  {offer?.user.name} not  given any Feedback yet</p>
                                      </div>
                                  }
                                </>
                              }
                            </div>
                        }
                      </>
                      <br />
                      <>
                        {
                          (feedback == null) &&
                          <div className='tradestart__info' >
                            <h5>Feedback for {isSeller ? buyer.name : offer?.user.name} </h5>
                            <div className="inputbox">
                              <textarea placeholder="Leave your feedback here ..." name="feedback" value={feedbackText} onChange={e => setFeedbackText(e.target.value)} />
                              <br />
                              <button className="btn btn--outline" > <div className='do_vote' >
                                {
                                  vote == 1 ?
                                    <span>
                                      <FaThumbsUp />
                                    </span>
                                    :
                                    <span title='Positive' onClick={e => setVote(1)} >
                                      <FiThumbsUp />
                                    </span>
                                }
                                {
                                  vote == 0 ?
                                    <span  >
                                      <FaThumbsDown />
                                    </span>
                                    :
                                    <span onClick={e => setVote(0)}>
                                      <FiThumbsDown />
                                    </span>
                                }
                              </div>
                              </button>
                            </div><br />
                            <div className='text-right'>
                              {
                                submitting ?
                                  <div>
                                    <Loading width={35} />
                                    <p>Please wait ...</p>
                                  </div>
                                  :
                                  <button className='btn btn--outline' onClick={e => feedbackHandler()} >Submit</button>
                              }
                            </div>
                          </div>
                        }
                      </>
                      <br />
                    </>
                  }
                  <div className="text-right">
                    <button className="btn">Report</button>
                  </div>
                </>
              }
            </div>
            <div className="tradestart__right tradestart__info chat_component">
              <div className='chat_header'>
                <div className='name_photo' >
                  <div className='profile_photo'>
                    <img src={`${baseURL}/${buyer?.pp}`} />
                  </div>
                  <div className='user_details'>
                    <p  >Harry
                      {/* <small className='active' > Typing...</small>  */}
                    </p>
                    <small className='active'> {getActiveStatus(buyer?.lastSeen)} </small>
                  </div>
                </div>
                <div className="icons">
                  <span> <CiSearch /> </span>
                  <span> <IoMdCall /> </span>
                  <span> <BiVideoPlus /> </span>
                  <span> <TbReportSearch /> </span>
                </div>
              </div>
              <div id='chat_history' className='chat_history'>
                {
                  getChatHistoryState.chatHistory.map((chat: any, i: any) => (
                    <React.Fragment key={i} >
                      {
                        isMe(chat?.sender?._id ? chat?.sender?._id : chat?.sender) ?
                          <div className='my_text_wrap'>
                            <div className='my_text' >
                              <div className='text_details'>
                                <div className='text' >
                                  <p> {chat.message} </p>
                                  <small className='time'> {getSmsTime(chat?.createdAt)} </small>
                                </div>
                              </div>
                            </div>
                          </div> :
                          <div className="opponent_text_wrap">
                            <div className='opponent_text' >
                              <div className='text_details'>
                                <div className='opp_pp'>
                                  <img src={`${baseURL}/${buyer?.pp}`} />
                                </div>
                                <div className='text' >
                                  <p> {chat?.message} </p>
                                  <small className='time' > {getSmsTime(chat?.createdAt)}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                    </React.Fragment>
                  ))
                }
              </div>
              <div className='chat_footer'>
                <div className="chat_footer_content">
                  <div className='input_icons'>
                    <span><ImAttachment /> </span>
                    <span><BsMic /></span>
                    <span><TbReportMoney /></span>
                  </div>
                  <form onSubmit={e => sendSms(e)} className="inputbox">
                    <input value={SMSText} onChange={e => setSMSText(e.target.value)} type="text" placeholder="Type Message" name="password" />
                    <BsEmojiSmile />
                  </form>
                  <div className='send_icon cp ' onClick={e => sendSms(e)} >
                    <span>  <AiOutlineSend /> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default TradeStart;
