export const coinData=   [
    {
      id: 1,
      icon: "/icons/ethereum.svg",
      title: "Token1",
      value: "Binance",
      subtitle: "TK1",
      address: '0xD8721c40f3edfC95e4675eFbfCeafC5A1597C30d'
    },   {
      id: 1,
      icon: "/icons/ethereum.svg",
      title: "Token2",
      value: "Binance",
      subtitle: "TK2",
      address: '0x7636576b9bef6DD3CaC99e24AA1e8F12886E59Cb'
    },   {
      id: 1,
      icon: "/icons/ethereum.svg",
      title: "Token3",
      value: "Binance",
      subtitle: "TK3",
      address: '0x0708a0f7c23330B8Ac7A931fC784D95FCf9AbaB8'
    },   {
      id: 1,
      icon: "/icons/ethereum.svg",
      title: "Token4",
      value: "Binance",
      subtitle: "TK4",
      address: '0xb1bEe2968be5794C22335e94450d38B698d363E1'
    },   {
      id: 1,
      icon: "/icons/ethereum.svg",
      title: "Token5",
      value: "Binance",
      subtitle: "TK5",
      address: '0x1bBa9cDF9582CfF9456159f0f1f7fDec6E65A084'
    },   {
      id: 1,
      icon: "/icons/ethereum.svg",
      title: "Token6",
      value: "Binance",
      subtitle: "TK6",
      address: '0xB33f0058473aFEd17D1ff31085B8fD3732A270b9'
    }, 
  ];