import { Link } from "react-router-dom";

export type TArticleProps = {
  title: string;
  image: string;
  variant?: "default" | "small";
};

const Article = ({ title, image, variant = "default" }: TArticleProps) => {
  return (
    <article className={`news ${variant === "small" ? "news--small" : ""}`}>
      <Link to="/" className="news__image">
        <img src={image} alt={title} />
      </Link>
      <div className="news__factfud">
        <small>Fact/FUD</small>
        <div className="buttons">
          <button className="btn btn--outline">Fact 😚</button>
          <button className="btn btn--outline">FUD 😦</button>
        </div>
      </div>
      <div className="news__content">
        <h5>
          <Link to="/">{title}</Link>
        </h5>
        <p>
          Maple is down 24.7& in the past day as crypto hedge fund Orthonal
          Trading defaults on $36 million in dollar.
        </p>
        <ul className="news__meta">
          <li className="author">The Block</li>
          <li className="duration">3 hours ago</li>
          <li className="category">
            <img src="/icons/ethereum.svg" alt="icon" /> ETH
          </li>
        </ul>
      </div>
    </article>
  );
};

export default Article;
