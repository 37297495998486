import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import siteLogo from "../../assets/images/sitelogo.png";
import siteLogoMobile from "../../assets/images/sitelogo-mobile.png";
import {
  FiBell,
  FiChevronDown,
  FiLogIn,
  FiLogOut,
  FiMail,
  FiMenu,
  FiSettings,
  FiX,
} from "react-icons/fi";
import { RiBarChart2Line, RiHistoryLine } from "react-icons/ri";
import { IUser, UserState } from "../../type";
import { appState, authAppState, userState } from "../../state/state";
import { useRecoilState } from 'recoil'
import { baseURL, frontendSocket, getCreationTime } from "../../util/utils";
import { toast } from "react-toastify";
import { copyText, NOTIFICATION_STATUS, NOTIFICATION_TYPE, OFFER_NOTIFICATION_TYPE } from "../../util/constant";
import axios from "axios";
import authHeader from "../../services/auth-header";
type Props = {
  user: IUser,
}

const SiteHeaderAlt: React.FC<Props> = () => {
  const [user, setUser] = useRecoilState(userState)
  const [getAppState, setAppState] = useRecoilState(appState)
  const [getAuthAppState, setAuthAppState] = useRecoilState(authAppState)
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [mobileHeader, setMobileHeader] = useState<boolean>(false);
  const [getAuthState, setAuthState] = useRecoilState(authAppState)

  // Sticky Header
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      });
    }
    return () => {
      window.removeEventListener("scroll", () => { });
    };
  });
  const readNOtificatoin = async (notification: any) => {
    await axios.get(`${baseURL}/api/users/readNotification/${notification._id}`, { headers: authHeader() })
    var notifications = await axios.get(`${baseURL}/api/users/notifications`, { headers: authHeader() })
    setAuthState({ ...getAuthState, notifications: notifications.data })
    window.location.href = `/${notification.message?.page}?offerID=${notification.message.offerID}&index=${notification.message.orderID}`
  }
  const handleLogout = (e: SyntheticEvent) => {
    e.preventDefault();
    window.localStorage.removeItem("user")
    toast.success("Successfully Logged out", { autoClose: 1500, position: 'top-center' })
    setTimeout(() => {
      return window.location.href = "/login"
    }, 1500);
  };

  return (
    <header
      className={`header header--alt ${isSticky ? "header--sticky" : ""}`}
    >
      <div className="container">
        <div className="header__inner">
          <Link to="/" className="header__logo">
            <img src={siteLogo} alt="NOM" />
            <img
              src={siteLogoMobile}
              alt="NOM"
              className="mobilelogo"
            />
          </Link>
          <div className="header__buysell">
            <div className="header__buy">
              <button className="btn">
                Buy <FiChevronDown />
              </button>
              <div className="header__buyselloption">
                <ul>
                  {
                    getAppState.tokens.map((token: any, i: any) => (
                      <li key={i}>
                        <a href={`/offers?action=sell&paytoken=${token.address}`}>
                          <img src={`${baseURL}/${token.logo}`} alt="icon" />
                          {token.name}
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="header__sell">
              <button className="btn">
                Sell <FiChevronDown />
              </button>
              <div className="header__buyselloption">
                <ul>
                  {
                    getAppState.tokens.map((token: any, i: any) => (
                      <li key={i}>
                        <a href={`/offers?action=sell&paytoken=${token.address}`}>
                          <img src={`${baseURL}/${token.logo}`} alt="icon" />
                          {token.name}
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
          <nav className={`header__menu ${mobileHeader ? "visible" : ""}`}>
            <ul>
              <li>
                <Link to="/tradehistory">Wallet</Link>
              </li>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
            </ul>
            <div className="header__menubuttons">
              <Link to="/offers" className="btn">
                Buy
              </Link>
              <Link to="/createoffer" className="btn">
                Sell
              </Link>
            </div>
            <button className="close" onClick={() => setMobileHeader(false)}>
              <FiX />
            </button>
          </nav>
          <div className="header__useroptions">
            <div className="header__user">
              <Link to="/profile" className="header__usertoggle">
                <div>
                  <span>{user.name}</span>
                  <span>{user.publicKey.substring(0, 6) + "..."}</span>
                </div>
                <img
                  src={`${baseURL}/${user.pp}`}
                  alt="#"
                />
              </Link>
              <div className="header__usermenu">
                <h6>
                  <Link to={"/profile"}>
                    My Profile
                  </Link>
                </h6>
                <p>
                  <span className="d_none copyaddress" > {user.publicKey}   </span>
                  <span className="cp" onClick={e => { copyText(user.publicKey, "Wallet Address Copied") }} >{user.publicKey.substring(0, 10) + "..."}</span>
                </p>

                <ul>
                  <li>
                    <Link to="/accountsetting">
                      <FiSettings /> Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="/tradehistory">
                      <RiHistoryLine /> Trade History
                    </Link>
                  </li>
                  <li>
                    <button onClick={(e) => handleLogout(e)}>
                      <FiLogOut /> Log Out
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header__notification">

              <Link to="/" className={`header__notificationtoggle ${(getAuthAppState.notifications[0]?.status == NOTIFICATION_STATUS.UNREAD) ? "active" : ''}`}>
                <FiBell />
              </Link>
              <div className="header__notificationmenu">
                <ul>
                  <li>
                    <Link to="/">
                      <FiMail /> Inbox
                    </Link>
                  </li>
                  <>
                    {
                      getAuthAppState.notifications.slice(0, 5).map((notification: any, i: any) => {
                        switch (notification.type) {
                          case NOTIFICATION_TYPE.OFFER:
                            return (
                              <li onClick={e => readNOtificatoin(notification)} key={notification._id} style={{ borderTop: "1px dashed #91919185" }}  >
                                <a href="#" >
                                  {
                                    (notification.message.type == OFFER_NOTIFICATION_TYPE.DEPOSITED) &&
                                    <span >
                                      <div>
                                        <span className={`${notification.status}`}>
                                          <FiLogIn />{notification.message.title}
                                        </span>
                                        <br />
                                        <small> <span style={{ visibility: "hidden" }} ><FiLogIn /></span>  {notification.message?.text}  </small>
                                        <br />
                                        <small>
                                          <span style={{ visibility: "hidden" }} ><FiLogIn /></span>
                                          {getCreationTime(notification.timestamp)}
                                        </small>
                                      </div>
                                    </span>
                                  }
                                  {
                                    (notification.message.type == OFFER_NOTIFICATION_TYPE.COMPLETED) &&
                                    <span >
                                      <div>
                                        <span className={`${notification.status}`}>
                                          <FiLogIn />{notification.message.title}
                                        </span>
                                        <br />
                                        <small> <span style={{ visibility: "hidden" }} ><FiLogIn /></span>  {notification.message?.text}  </small>
                                        <br />
                                        <small>
                                          <span style={{ visibility: "hidden" }} ><FiLogIn /></span>
                                          {getCreationTime(notification.timestamp)}
                                        </small>
                                      </div>
                                    </span>
                                  }
                                </a>
                              </li>)
                          default:
                            return ""
                        }
                      })
                    }
                  </> 
                </ul>
              </div>
            </div>
          </div>
          <button
            className="btn header__menutoggler"
            onClick={() => setMobileHeader(!mobileHeader)}
          >
            <FiMenu />
          </button>
        </div>
      </div>
    </header>
  );
};

export default SiteHeaderAlt;
