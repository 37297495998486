import { atom } from 'recoil'
import { NOTIFICATION_STATUS } from '../util/constant';

export const userState = atom({
    key: 'userState',
    default: {
        tokens: [],
        loading: true,
        _id: '',
        timeZone: 0,
        notification: true,
        bio: '',
        name: '',
        email: '',
        password: '',
        privateKey: '',
        publicKey: '',
        upVote: '',
        lastSeen: '',
        downVote: '',
        pp: ''
    },
});

export const authAppState = atom({
    key: 'authAppState',
    default: {
        notifications: [{ status: NOTIFICATION_STATUS.READ }],
        chatHistory:[]as any[]
    }
})
export const chatHistoryState = atom({
    key: 'chatHistoryState',
    default: {
        chatHistory:[]as any[]
    }
})

export const appState = atom({
    key: 'appState',
    default: {
        loading: true,
        tokens: [],
    }
})

