import React, { useEffect, useState } from "react";
import { FiArrowDown, FiArrowUp, FiSliders, FiThumbsUp } from "react-icons/fi";
import { TSelectItem } from "../globalTypes";
import SelectionBox from "../components/items/SelectionBox";
import SiteLayout from "../components/layout/SiteLayout";
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { coinData } from "../util/coinData";
import axios from "axios";
import authHeader from "../services/auth-header";
import { useRecoilState } from "recoil";
import { appState, userState } from "../state/state";
import { baseURL, getCreationTime, getLastActiveTime } from "../util/utils";
import Loading from "../components/loading";
import { getTotalSoldAmount } from "../util/constant";

interface Data {
  sellerMID: string;
  buyToken: {
    address: string;
  };
  sellToken: {
    address: string;
  };
}
const Offers = () => {
  const [buyCoinType, setBuyCoinType] = useState<TSelectItem>(coinData[0]);
  const [payCoinType, setPayCoinType] = useState<TSelectItem>(coinData[1]);
  const [allOffer, setAllOffer] = useState([]);
  const [offers, setOffers] = useState([]);

  const [getAppState, setAppState] = useRecoilState(appState) 
  const [isDataSattled, setIsDataSattled] = useState(true)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState(3)



  const handleBuyCoinType = (coin: TSelectItem) => {
    setBuyCoinType(coin);
  };

  const handlePayCoinType = (coin: TSelectItem) => {
    setPayCoinType(coin);
  };

  const getOfferStatus = (status: any) => {
    if (status == 0) return (<span className="tag tag--success">New Offer</span>)
    if (status == 1) return (<span className="tag tag--danger">Pending</span>)
    if (status == 2) return (<span className="tag tag--warning">Completed</span>)
    else return (<span className="tag tag--success">New Offer</span>)
  }
  const loadMore = () => {
    setRowCount(rowCount + 3)
  } 
  const fetchOffers = () => {
    return axios.get("https://api.nom.us/api/offers/getAllOffers", { headers: authHeader() })
      .then((resp) => {
        setAllOffer(resp.data.record)
        var receivedOffers = resp.data.record;
        const params = new URLSearchParams(new URL((window.location).toString()).search);
        const action = params.get('action');
        const paytoken = params.get('paytoken');
        const buytoken = params.get('buytoken');

        if (paytoken && buytoken) {
          receivedOffers = receivedOffers.filter((d: Data) => {
            console.log(d.sellToken.address, d.buyToken.address)
            console.log(paytoken, buytoken)
            return d.sellToken.address === paytoken && d.buyToken.address === buytoken;
          });
        } else {
          setOffers(receivedOffers)
        }
        setOffers(receivedOffers);
        setLoading(false)
      });
  }


  const navigate = useNavigate();
  useEffect(() => {
    fetchOffers();
  }, []);

  const setData = () => {
    if (getAppState.tokens?.length > 0 && !isDataSattled) {
      handlePayCoinType(getAppState.tokens[0])
      handleBuyCoinType(getAppState.tokens[1])
      setIsDataSattled(true)
    }
  }
  const filter = () => {
    var filteredOffer = allOffer.filter((d: Data) => {
      return d.sellToken.address === payCoinType.address && d.buyToken.address === buyCoinType.address;
    });
    navigate(`/offers?action=paytoken=${payCoinType.address}&buytoken=${buyCoinType.address}`);
    setOffers(filteredOffer)
  }
  return (
    <SiteLayout variant="withoptions">
      <div className="offers">
        <>
          {setData()}
        </>
        <div className="container">
          <div className="offers__inner">
            <div className="offers__left">
              <div className="offers__selectcoin">
                <h4>Buy</h4>
                {
                  loading ?
                    <div className="text-center">
                      <Loading width={40} />
                      <p>Loading...</p>
                    </div> :
                    <>
                      {
                        getAppState.tokens.length < 1 ?
                          <div className="text-center">
                            <Loading width={40} />
                            <p>Loading...</p>
                          </div> :
                          <SelectionBox
                            data={getAppState.tokens}
                            currentData={payCoinType}
                            changeHandler={handlePayCoinType}
                            alwaysOpen
                          />
                      }
                    </>
                }
              </div>
              <div className="offers__selectcoin">
                <h4>Pay via</h4>
                {
                  loading ?
                    <div className="text-center">
                      <Loading width={40} />
                      <p>Loading...</p>
                    </div> :
                    <>
                      {
                        getAppState.tokens.length < 1 ?
                          <div className="text-center">
                            <Loading width={40} />
                            <p>Loading...</p>
                          </div> :
                          <SelectionBox
                            data={getAppState.tokens}
                            currentData={buyCoinType}
                            changeHandler={handleBuyCoinType}
                            alwaysOpen
                          />
                      }
                    </>
                }
              </div>
              <button className="btn" onClick={e => filter()}>Filter </button>
            </div>
            <div className="offers__right">
              <h2>Buy & Sell</h2>
              <div className="checkbox--boxy">
                <input
                  type="checkbox"
                  name="filtervendors"
                  id="filtervendors"
                />
                <label htmlFor="filtervendors">
                  Vendors active in last 10 min
                </label>
              </div>
              <div className="offers__rightinner">
                <div className="table-responsive">
                  <table className="table" border={0}>
                    <thead>
                      <tr>
                        <th className="text-left">Buy from</th>
                        <th className="text-left">Plateform</th>
                        <th className="text-center">Pay with</th>
                        <th className="text-left">Purchase Limit</th>
                        {/* <th className="text-center">Avg. trade speed</th> */}
                        <th className="text-left">Price</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(offers.slice(0, rowCount)).map((offer: any, i) => {
                        return (
                          <>
                            {
                              (getTotalSoldAmount(offer.soldRecord) < offer.maxAmount) &&
                              <tr key={offer._id}>
                                <td className="text-left">
                                  <div className="buyfrom">
                                    <div className="image">
                                      {
                                        offer.user?.pp ?
                                          <img
                                            src={`${baseURL}/${offer?.user?.pp}`}
                                            alt="pp"
                                          /> :
                                          <img src="/images/user.png" />
                                      }
                                    </div>
                                    <div className="content">
                                      <small>{offer.user?.name}</small>
                                      <div className="likes">
                                        <FiThumbsUp /> <small>{offer.user?.upVote}</small>
                                      </div>
                                      <span className="tiny-text">{getCreationTime(offer?.createdAt)} </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="paywith">
                                    <div className="image">
                                      <img src="/icons/ethereum.svg" alt="ethereum" />
                                    </div>
                                    <div className="content">
                                      <small>{offer.sellToken?.chain}</small>
                                      <span className="tiny-text">
                                        Instant Confirmation
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div>
                                    <small> {offer.buyToken?.name} </small><br /> {getOfferStatus(offer.offerStatus)}
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="minmax">
                                    <small>Min :{offer.splitedTrade?offer.minAmount:offer.maxAmount} {offer.buyToken.symbol} </small>
                                    <small>Max :  {offer.maxAmount - (getTotalSoldAmount(offer.soldRecord))} {offer.buyToken.symbol} </small>
                                  </div>
                                </td>
                                {/* <td className="text-left">
                            <div className="perprice">
                              <p className="font-semibold">0.03Eth</p>
                              <h6 className="success">
                                <FiArrowUp />
                                <small> 0.11%</small>
                              </h6>
                            </div>
                          </td> */}
                                <td>
                                  <span title={` Price per ${offer.sellToken.symbol} `} > {offer.price} [ PP: {offer.sellToken.symbol} ]  </span>
                                </td>
                                <td className="text-right">
                                  <div>
                                    <button className="btn btn--gradient btn--extrasmall" onClick={() => { navigate('/confirmbuy/' + offer._id, { replace: true }) }}>Trade</button>
                                  </div>
                                </td>
                              </tr>
                            }
                          </>
                        )
                      })
                      }
                      <tr>
                        <td colSpan={7} className="text-center" > Want to propose New Offer ?  <Link to={"/createoffer"} > Create Offer</Link>  </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {
                ((offers.length > 3) && (offers.length > rowCount)) ?
                  <div className="offers__loadmore">
                    <button onClick={e => loadMore()} className="btn">Load more</button>
                  </div> : ''
              }
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default Offers;
