import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import siteLogo from "../../assets/images/sitelogo.png";
import siteLogoMobile from "../../assets/images/sitelogo-mobile.png";

const SiteHeaderOnlyLogo = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  // Sticky Header
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      });
    }

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  });

  return (
    <header className={`header ${isSticky ? "header--sticky" : ""}`}>
      <div className="container">
        <div className="header__inner">
          <Link to="/" className="header__logo">
            <img src={siteLogo} alt="NOM" />
            <img
              src={siteLogoMobile}
              alt="NOM"
              className="mobilelogo"
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default SiteHeaderOnlyLogo;
