import StatisticChart from "../../assets/images/statistic-chart.svg";
import StatisticChartDown from "../../assets/images/statistic-chart-down.svg";

export type TStatistic = {
  icon: string;
  title: string;
  price: number;
  type: "upgrade" | "downgrade";
};

const Statistic = ({ icon, title, price, type }: TStatistic) => {
  return (
    <div className="statistic">
      <div className="statistic__inner">
        <div className="statistic__top">
          <div className="statistic__icon">
            <img src={icon} alt="icon" />
          </div>
          <div className="statistic__info">
            <h4>{title}</h4>
            <p>BNB</p>
          </div>
        </div>
        <div className="statistic__bottom">
          <h4 className="statistic__rate">
            ${price.toFixed(2)}{" "}
            {type === "upgrade" ? (
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.83334 17.3333V3.04167L1.70834 9.16667L0.833344 8.392L8.41668 0.80867L16 8.392L15.125 9.16667L9.00001 3.04167V17.3333H7.83334Z"
                  fill="#70FF00"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.16666 0.666664L8.16666 14.9583L14.2917 8.83333L15.1667 9.608L7.58332 17.1913L-1.07811e-05 9.608L0.874989 8.83333L6.99999 14.9583L6.99999 0.666663L8.16666 0.666664Z"
                  fill="#F0B90B"
                />
              </svg>
            )}
          </h4>
          <div className="statistic__chart">
            {type === "upgrade" ? (
              <img src={StatisticChart} alt="chart" />
            ) : (
              <img src={StatisticChartDown} alt="chart" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistic;
