// eslint-disable-next-line
import { FiChevronLeft, FiChevronRight, FiSend } from "react-icons/fi";
import { Link } from "react-router-dom";
import SiteLayout from "../components/layout/SiteLayout";

const Transactions = () => {
  return (
    <SiteLayout variant="withoptions">
      <div className="transactions">
        <div className="container">
          <div className="transactions__inner">
            <h1 className="font-medium">Latest Transactions</h1>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-left">Transactions</th>
                    <th className="text-center">Details</th>
                    <th className="text-center">Status</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--danger">Pending</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--danger">Pending</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--danger">Pending</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--danger">Pending</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div className="type">
                        <div className="icon success">
                          <FiSend />
                        </div>
                        <div className="content">
                          <p>Sent out</p>
                          <span className="tiny-text">Nov 28, 12:20 AM</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <small>Sent to XuaTocdo in trade 57452</small>
                    </td>
                    <td className="text-center">
                      <span className="tag tag--success">Complete</span>
                    </td>
                    <td className="text-right">
                      <p>-800.741547 USDT</p>
                      <small>-745.25 USD</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            {/* <div className="pagination text-center">
              <ul>
                 <li>
                  <Link to="/transactions" className="prev">
                    <FiChevronLeft />
                  </Link>
                </li> 
                <li>
                  <Link to="/transactions">1</Link>
                </li>
                <li>
                  <Link to="/transactions">2</Link>
                </li>
                <li>
                  <Link to="/transactions">3</Link>
                </li>
                <li>
                  <Link to="/transactions" className="next">
                    <FiChevronRight />
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default Transactions;
