import { SyntheticEvent, useState } from "react";
import { FiChevronDown, FiChevronRight, FiHelpCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Article from "../components/items/Article";
import SiteLayout from "../components/layout/SiteLayout";

const data = [
  {
    name: "12:00",
    uv: 15000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "14:00",
    uv: 28000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "16:00",
    uv: 55000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "18:00",
    uv: 40000,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "20:00",
    uv: 78000,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "22:00",
    uv: 60000,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "24:00",
    uv: 72000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "02:00",
    uv: 55000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "04:00",
    uv: 78000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "06:00",
    uv: 60000,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "08:00",
    uv: 85000,
    pv: 4300,
    amt: 2100,
  },
];

const customizedTooltip = (props: any) => {
  return <div className="custom-tooltip">{props.payload[0]?.value}</div>;
};

const Coinpage = () => {
  const [convertTo, setConvertTo] = useState<string>("USD");
  const [selectConvertTo, setSelectConvertTo] = useState<boolean>(false);

  const handleSelect = (e: SyntheticEvent, value: string) => {
    setConvertTo(value);
    setSelectConvertTo(false);
  };

  return (
    <SiteLayout variant="withoptions">
      <div className="coinpage">
        <div className="container">
          <div className="coinpage__inner">
            <div className="coinpage__head">
              <div className="icon">
                <img src="/icons/ethereum.svg" alt="ethereum" />
              </div>
              <div className="content">
                <small>
                  Rank # 1{" "}
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.37506 11.8542L8.00006 10.2708L10.6251 11.875L9.93756 8.875L12.2501 6.875L9.20839 6.60417L8.00006 3.77083L6.79173 6.58333L3.75006 6.85417L6.06256 8.875L5.37506 11.8542ZM8.00006 12.2292L4.54173 14.3125C4.38895 14.4097 4.22923 14.4514 4.06256 14.4375C3.89589 14.4236 3.75006 14.3681 3.62506 14.2708C3.50006 14.1736 3.40284 14.0522 3.33339 13.9067C3.26395 13.7606 3.25006 13.5972 3.29173 13.4167L4.20839 9.47917L1.14589 6.83333C1.007 6.70833 0.920336 6.56583 0.885892 6.40583C0.850892 6.24639 0.86117 6.09028 0.916725 5.9375C0.972281 5.78472 1.05561 5.65972 1.16673 5.5625C1.27784 5.46528 1.43061 5.40278 1.62506 5.375L5.66673 5.02083L7.22923 1.3125C7.29867 1.14583 7.40645 1.02083 7.55256 0.9375C7.69811 0.854167 7.84728 0.8125 8.00006 0.8125C8.15284 0.8125 8.30228 0.854167 8.44839 0.9375C8.59395 1.02083 8.70145 1.14583 8.77089 1.3125L10.3334 5.02083L14.3751 5.375C14.5695 5.40278 14.7223 5.46528 14.8334 5.5625C14.9445 5.65972 15.0278 5.78472 15.0834 5.9375C15.1389 6.09028 15.1495 6.24639 15.1151 6.40583C15.0801 6.56583 14.9931 6.70833 14.8542 6.83333L11.7917 9.47917L12.7084 13.4167C12.7501 13.5972 12.7362 13.7606 12.6667 13.9067C12.5973 14.0522 12.5001 14.1736 12.3751 14.2708C12.2501 14.3681 12.1042 14.4236 11.9376 14.4375C11.7709 14.4514 11.6112 14.4097 11.4584 14.3125L8.00006 12.2292Z"
                      fill="url(#paint0_linear_5_12619)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_5_12619"
                        x1="0.865601"
                        y1="0.8125"
                        x2="13.3214"
                        y2="17.5005"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#9C37FD" />
                        <stop offset="1" stopColor="#15BFFD" />
                      </linearGradient>
                    </defs>
                  </svg>
                </small>
                <h2>
                  Ethereum <span className="tiny-text">ETH</span>
                </h2>
                <h2>
                  $1254.39 <small className="success">+14.02%</small>
                </h2>
              </div>
            </div>
            <div className="coindetails">
              <div className="coindetails__left">
                <div className="coindetails__tables">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Market Cap</span>
                          </td>
                          <td className="text-right">
                            <small>$210,484,149,351</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">
                              24 Hour Trading vol
                            </span>
                          </td>
                          <td className="text-right">
                            <small>$210,484,149</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">
                              Fully Diluted Valuation
                            </span>
                          </td>
                          <td className="text-right">
                            <small>$210,484,149,351</small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">
                              Circulating Supply
                            </span>
                          </td>
                          <td className="text-right">
                            <small>$210,484,149,351</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Total Supply</span>
                          </td>
                          <td className="text-right">
                            <small>$210,484</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Max Supply</span>
                          </td>
                          <td className="text-right"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
                <div className="coinresearch">
                  <ul className="coinresearch__options">
                    <li>
                      <button className="active">Overview</button>
                    </li>
                    <li>
                      <button>Markets</button>
                    </li>
                    <li>
                      <button>Historical Data</button>
                    </li>
                    <li>
                      <button>News</button>
                    </li>
                  </ul>
                  <div className="coinresearch__content">
                    <div className="coinresearch__overview">
                      <div className="overview">
                        <div className="overview__top">
                          <div className="tabs">
                            <button className="active">Price</button>
                            <button>Market cap</button>
                            <button>Trading view</button>
                          </div>
                          <div className="tabs">
                            <button className="active">24h</button>
                            <button>7D</button>
                            <button>14D</button>
                            <button>30D</button>
                            <button>90D</button>
                          </div>
                        </div>
                        <div className="overview__chart">
                          <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                              width={500}
                              height={400}
                              data={data}
                              margin={{
                                top: 0,
                                right: 0,
                                left: -30,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="20%"
                                    stopColor="rgba(10, 147, 150, 0.21)"
                                    stopOpacity={1}
                                  />
                                  <stop
                                    offset="100%"
                                    stopColor="rgba(255, 255, 255, 0)"
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                              </defs>
                              <XAxis
                                dataKey="name"
                                color="#BDBDBD"
                                fill="#BDBDBD"
                                fontSize={7}
                                axisLine={false}
                                tickLine={false}
                              />
                              <YAxis
                                dataKey="uv"
                                color="#BDBDBD"
                                fill="#BDBDBD"
                                fontSize={7}
                                axisLine={false}
                                tickLine={false}
                              />
                              <Tooltip content={customizedTooltip} />
                              <Area
                                type="monotone"
                                dataKey="uv"
                                stroke="#0096C7"
                                strokeWidth={3}
                                fill="url(#colorUv)"
                                activeDot={{
                                  fill: "#0096C7",
                                  stroke: "#ffffff",
                                  strokeWidth: 2,
                                  r: 6,
                                }}
                                dot={{
                                  fill: "#0096C7",
                                  stroke: "#ffffff",
                                  strokeWidth: 2,
                                  r: 6,
                                }}
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="details">
                  <div>
                    <h6>ETH Price Live Data</h6>
                    <p>
                      The live Ethereum price today is $1,253.86 USD with a
                      24-hour trading volume of $6,257,639,524 USD. We update
                      our ETH to USD price in real-time. Ethereum is down 1.87%
                      in the last 24 hours. The current CoinMarketCap ranking is
                      #2, with a live market cap of $153,439,617,176 USD. It has
                      a circulating supply of 122,373,866 ETH coins and the max.
                      supply is not available.
                    </p>
                    <p>
                      If you would like to know where to buy Ethereum at the
                      current rate, the top cryptocurrency exchanges for trading
                      in Ethereum stock are currently Binance, BTCEX, MEXC,
                      Bitrue, and OKX. You can find others listed on our crypto
                      exchanges page.
                    </p>
                  </div>
                  <div>
                    <h6>What Is Ethereum (ETH)?</h6>
                    <p>
                      Ethereum is a decentralized open-source blockchain system
                      that features its own cryptocurrency, Ether. ETH works as
                      a platform for numerous other cryptocurrencies, as well as
                      for the execution of decentralized smart contracts.
                    </p>
                  </div>
                  <div>
                    <h6>What Is Ethereum (ETH)?</h6>
                    <p>
                      Ethereum is a decentralized open-source blockchain system
                      that features its own cryptocurrency, Ether. ETH works as
                      a platform for numerous other cryptocurrencies, as well as
                      for the execution of decentralized smart contracts.
                    </p>
                    <p>
                      Ethereum was first described in a 2013 whitepaper by
                      Vitalik Buterin. Buterin, along with other co-founders,
                      secured funding for the project in an online public crowd
                      sale in the summer of 2014. The project team managed to
                      raise $18.3 million in Bitcoin, and Ethereum’s price in
                      the Initial Coin Offering (ICO) was $0.311, with over 60
                      million Ether sold. Taking Ethereum’s price now, this puts
                      the return on investment (ROI) at an annualized rate of
                      over 270%, essentially almost quadrupling your investment
                      every year since the summer of 2014.
                    </p>
                  </div>
                </div>
              </div>
              <div className="coindetails__right">
                <div className="coindetails__converter">
                  <h6>Covert ETH to USD</h6>
                  <div className="boxes">
                    <div className="inputbox">
                      <legend>ETH</legend>
                      <input type="text" placeholder="Enter ETH amount" />
                    </div>
                    <div className="inputselectbox">
                      <legend
                        onClick={() => setSelectConvertTo(!selectConvertTo)}
                      >
                        {convertTo} <FiChevronDown />
                      </legend>
                      <ul className={selectConvertTo ? "visible" : ""}>
                        <li onClick={(e) => handleSelect(e, "USD")}>USD</li>
                        <li onClick={(e) => handleSelect(e, "ETH")}>ETH</li>
                        <li onClick={(e) => handleSelect(e, "BTC")}>BTC</li>
                        <li onClick={(e) => handleSelect(e, "TET")}>TET</li>
                      </ul>
                      <input type="text" />
                    </div>
                  </div>
                  <p>ETH = $1236.00 </p>
                </div>

                <div className="coindetails__info">
                  <h6>ETH Price Statistics</h6>
                  <div className="table-responsive noborder">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Ethereum Price</span>
                          </td>
                          <td className="text-right">
                            <small>$210.00</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">
                              @4h Low / 24h High
                            </span>
                          </td>
                          <td className="text-right">
                            <small>$210,48428/ 2147.32</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">7d Low / 7d High</span>
                          </td>
                          <td className="text-right">
                            <small>$210,48428/ 2147.32</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Trading volume</span>
                          </td>
                          <td className="text-right">
                            <small>$210,484,149,351</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Market Cap Rank</span>
                          </td>
                          <td className="text-right">
                            <small>#2</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">Market Cap</span>
                          </td>
                          <td className="text-right">
                            <small>$210,484,149,351</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">
                              Market Cap Dominance
                            </span>
                          </td>
                          <td className="text-right">
                            <small>17.241&</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">
                              Volume / Market Cap
                            </span>
                          </td>
                          <td className="text-right">
                            <small>0.21547</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="tiny-text">All-Time High</span>
                          </td>
                          <td className="text-right">
                            <small>
                              $210,48 <span className="danger">-85.32%</span>
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-right">
                    <span className="tiny-text">
                      Nov 10, 2021 (about 1 year)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="coinpage__markets">
              <div className="coinpage__marketshead">
                <h6>Ethereum Markets</h6>
                <div className="tabcontainer">
                  <div className="tabs">
                    <button className="active">All</button>
                    <button>CEX</button>
                    <button>DEX</button>
                  </div>
                </div>
                <Link to="/research" className="btn-readmore">
                  See all Markets <FiChevronRight />
                </Link>
              </div>
              <div className="table-responsive noborder">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">Source</th>
                      <th className="text-left">Pairs</th>
                      <th className="text-left">Price</th>
                      <th className="text-center">+2% Depth</th>
                      <th className="text-center">Volume</th>
                      <th className="text-center">Volume %</th>
                      <th className="text-center">
                        Confidence{" "}
                        <FiHelpCircle title="Confidence Help Center" />
                      </th>
                      <th className="text-center">
                        Liquidity score{" "}
                        <FiHelpCircle title="Liquidity Score Help Center" />
                      </th>
                      <th className="text-right">Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">1</td>
                      <td className="text-left">
                        <div className="walleticon">
                          <img src="/icons/wallets/binance.svg" alt="binance" />
                        </div>
                      </td>
                      <td className="text-left">
                        <small className="success">ETH/USDT</small>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>8.36%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">High</small>
                      </td>
                      <td className="text-center">
                        <small>985</small>
                      </td>
                      <td className="text-right">
                        <small>Recently</small>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">2</td>
                      <td className="text-left">
                        <div className="walleticon">
                          <img src="/icons/wallets/binance.svg" alt="binance" />
                        </div>
                      </td>
                      <td className="text-left">
                        <small className="success">ETH/USDT</small>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>8.36%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">High</small>
                      </td>
                      <td className="text-center">
                        <small>985</small>
                      </td>
                      <td className="text-right">
                        <small>Recently</small>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">3</td>
                      <td className="text-left">
                        <div className="walleticon">
                          <img
                            src="/icons/wallets/coinbase.svg"
                            alt="binance"
                          />
                        </div>
                      </td>
                      <td className="text-left">
                        <small className="success">ETH/USDT</small>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>8.36%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">High</small>
                      </td>
                      <td className="text-center">
                        <small>985</small>
                      </td>
                      <td className="text-right">
                        <small>Recently</small>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">4</td>
                      <td className="text-left">
                        <div className="walleticon">
                          <img src="/icons/wallets/binance.svg" alt="binance" />
                        </div>
                      </td>
                      <td className="text-left">
                        <small className="success">ETH/USDT</small>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>8.36%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">High</small>
                      </td>
                      <td className="text-center">
                        <small>985</small>
                      </td>
                      <td className="text-right">
                        <small>Recently</small>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">5</td>
                      <td className="text-left">
                        <div className="walleticon">
                          <img src="/icons/wallets/binance.svg" alt="binance" />
                        </div>
                      </td>
                      <td className="text-left">
                        <small className="success">ETH/USDT</small>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>8.36%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">High</small>
                      </td>
                      <td className="text-center">
                        <small>985</small>
                      </td>
                      <td className="text-right">
                        <small>Recently</small>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">6</td>
                      <td className="text-left">
                        <div className="walleticon">
                          <img
                            src="/icons/wallets/coinbase.svg"
                            alt="binance"
                          />
                        </div>
                      </td>
                      <td className="text-left">
                        <small className="success">ETH/USDT</small>
                      </td>
                      <td className="text-left">
                        <small>$12054.39</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>$854,742,127</small>
                      </td>
                      <td className="text-center">
                        <small>8.36%</small>
                      </td>
                      <td className="text-center">
                        <small className="success">High</small>
                      </td>
                      <td className="text-center">
                        <small>985</small>
                      </td>
                      <td className="text-right">
                        <small>Recently</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="coinpage__news">
              <div className="coinpage__newshead">
                <h6>Ethereum News</h6>
                <div className="tabcontainer">
                  <div className="tabs">
                    <button className="active">All</button>
                    <button>News</button>
                    <button>Alexandria</button>
                    <button>Articles</button>
                  </div>
                </div>
                <Link to="/" className="btn-readmore">
                  Read more <FiChevronRight />
                </Link>
              </div>
              <div className="coinpage__newsbody">
                <Article
                  title="Maple token down more than 23 & cut’s ties with Orthogonal
                      Trading following $36 million default"
                  image="/images/news-large.png"
                />
                <div className="rightside">
                  <Article
                    title="Vitalik Buterin Discusses his excitement for the future
                        of Ethereum"
                    image="/images/news-small.jpg"
                    variant="small"
                  />
                  <Article
                    title="Vitalik Buterin Discusses his excitement for the future
                        of Ethereum"
                    image="/images/news-small.jpg"
                    variant="small"
                  />
                  <Article
                    title="Vitalik Buterin Discusses his excitement for the future
                        of Ethereum"
                    image="/images/news-small.jpg"
                    variant="small"
                  />
                </div>
              </div>
              <br />
              <br />
              <div className="text-center">
                <button className="btn">Show More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default Coinpage;
