import Statistic from "../items/Statistic";
import IconBinanace from "../../assets/icons/binance.svg";
import IconEthereum from "../../assets/icons/ethereum.svg";
import IconZeronet from "../../assets/icons/zeronet.svg";

const Statistics = () => {
  return (
    <div className="statistics">
      <div className="container">
        <div className="statistics__inner">
          <Statistic
            icon={IconBinanace}
            title="Binance"
            price={201.36}
            type="upgrade"
          />
          <Statistic
            icon={IconEthereum}
            title="Binance "
            price={701.36}
            type="upgrade"
          />
          <Statistic
            icon={IconZeronet}
            title="ZeroNet"
            price={968.74}
            type="downgrade"
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
