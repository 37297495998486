import { useState } from "react";
import SiteLayout from "../components/layout/SiteLayout";
import RegisterImage from "../assets/images/register-image.png";
import { BiImageAdd } from "react-icons/bi";
import { SiHiveBlockchain } from "react-icons/si"
import { MdGeneratingTokens } from 'react-icons/md'
import { TbBrandAdobe } from "react-icons/tb"
import { BsHash } from "react-icons/bs"
import axios from "axios";
import { baseURL } from "../util/utils";
import { toast } from "react-toastify";
import authHeader from "../services/auth-header";
import Loading from "../components/loading";



const AddToken: React.FC = () => {
  const [name, setName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [address, setAddress] = useState("")
  const [chainName, setChainName] = useState("")
  const [tokenLogo, setTokenLogo] = useState<any>()
  const [isCreated, setIsCreated] = useState(false)
  const [isCreating, setIsCreating] = useState(false)


  const fileChangeHandler = (e: any) => {
    console.log("hello", e.target.files[0])
    setTokenLogo(e.target.files[0])
  }
  const submitHandler = async (e: any) => {
    e.preventDefault()
    var body = new FormData()
    await body.append("name", name)
    await body.append("symbol", symbol)
    await body.append("address", address)
    await body.append("chain", chainName)
    await body.append("file", tokenLogo)
    console.log("body is ", body)
    if (!name) return toast.error("Please Enter Token Name")
    if (!symbol) return toast.error("Please Enter Token Symbol")
    if (!address) return toast.error("Please Enter Token Address")
    if (!chainName) return toast.error("Please Enter Blockchain Name")
    setIsCreating(true)
    axios.post(`${baseURL}/api/tokens/registerToken`, body, { headers: authHeader() })
      .then(resp => {
        setTimeout(() => {
          toast.success("Successfully Created Token", { position: 'top-center', autoClose: 2000 })
          setIsCreated(true)
          setIsCreating(false)
        }, 500);
        setTimeout(() => {
          window.location.href = "/createoffer"
        }, 2200);
      })
      .catch(err => {
        setIsCreating(false)
        toast.error(err?.response?.data?.message)
      })
  }
  return (
    <SiteLayout variant="onlylogo" noFooter>
      <div className="register">
        <div className="container">
          <div className="register__inner">
            <div className="register__content">
              <h4 className="register__title">Create a new Token</h4>
              <div className="register__field">
                <label htmlFor="tokenName">Token Name</label>
                <div className="inputbox">
                  <MdGeneratingTokens />
                  <input onChange={e => setName(e.target.value)} type="text" placeholder="Enter Token Name" name="tokenName" />
                </div>
              </div>
              <div className="register__field">
                <label htmlFor="symbol"> Symbol</label>
                <div className="inputbox">
                  <TbBrandAdobe />
                  <input onChange={e => setSymbol(e.target.value)} type="text" placeholder="Enter Symbol (Ex.ETH)  " name="symbol" />
                </div>
              </div>
              <div className="register__field">
                <label htmlFor="tokenAddress">Token Address</label>
                <div className="inputbox">
                  <BsHash />
                  <input onChange={e => setAddress(e.target.value)} type="text" placeholder="Enter Token Address" name="tokenAddress" />
                </div>
              </div>
              <div className="register__field">
                <label htmlFor="chainName">Chain Name</label>
                <div className="inputbox">
                  <SiHiveBlockchain />
                  <input onChange={e => setChainName(e.target.value)} type="text" placeholder="Enter Chain Name (Ex:Ethereum) " name="chainName" />
                </div>
              </div>
              <div className="register__field">
                <label htmlFor="tokenLogo">Select Token Logo</label>
                <div className="inputbox">
                  <BiImageAdd />
                  <div style={{ cursor: "pointer" }} onClick={e => document.getElementById("token_logo")?.click()}>
                    <input type="text" disabled placeholder={tokenLogo?.name ? tokenLogo.name : "Select Token Logo"} name="tokenLogo" />
                  </div>
                  <input id="token_logo" onChange={e => fileChangeHandler(e)} accept="image/*" type="file" style={{ display: "none" }} placeholder="Select Token Logo " name="tokenLogo" />
                </div>
              </div>
              <div className="register__field">
                {
                  isCreating ?
                    <>
                      <p><Loading width={20} /> Token Creation in pending </p>
                    </> : ''
                }
                {
                  isCreated ?
                    <button className="btn btn--outline" >Token Registered</button> :
                    <button onClick={e => submitHandler(e)} className="btn btn--outline">Register Token </button>
                }
              </div>
            </div>
            <div className="register__image">
              <img src={RegisterImage} alt="register" />
            </div>
          </div>
        </div>
        <span className="blobbg register__blob"></span>
      </div>
    </SiteLayout>
  );
};

export default AddToken;
