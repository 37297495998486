import { IUser, UserAction, UserState } from "../type"
import * as actionTypes from "./actionTypes"


const initialState: UserState = {
  user: {
    _id: '',
    name: '',
    email: '',
    publicKey: '',
  },
  offerDetails:{
    wantToSell:'',
    getPaidWith:''
  }
}

const reducer = (
    state: UserState = initialState,
    action: UserAction
  ): UserState => {
    switch (action.type) {
      case actionTypes.SET_USER:
        const curUser: IUser = {
          _id: action.payload._id, // not really unique
          name: action.payload.name,
          email: action.payload.email,
          publicKey: action.payload.publicKey,
        }
        return {
          ...state,
          user: curUser,
        }
    }
    
    return state
  }
  
  export default reducer