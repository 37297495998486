import { useEffect, useState } from "react";
import { FiGlobe, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";

const SiteFooter = () => {
  const [theme, setTheme] = useState <String> ('dark');

  useEffect(()=>{
    
    // mode
    const mode= window.localStorage.getItem("mode")
    if(mode=="light_mode"){
      setTheme("light_mode")
    }else{
      setTheme("dark")
    }

  },[])
  function toggleTheme() {
    if (theme === 'dark') {
      setTheme('light_mode');
      document.body.classList.add('light_mode');
      window.localStorage.setItem("mode","light_mode")
    } else {
      setTheme('dark');
      document.body.classList.remove('light_mode');
      window.localStorage.removeItem("mode")

    }
  }
  return (
    <footer className="footer">
      <div className="container container--small">
        <div className="footer__inner">
          <div className="footer__left">
            <div className="footer__lefttop">
              <h2>
                <a href="!#">NOM</a>
              </h2>
              <a href="!#" className="btn btn--gradient">
                FAQ & Help
              </a>
            </div>
            <ul>
              <li>
                <a href="!#">Terms & Conditions</a>
              </li>
              <li>
                <a href="!#">Privacy & Policy</a>
              </li>
            </ul>
            <div className="footer__appoptions">
              <a href="!#" className="btn btn--gradient">
                <svg
                  width="39"
                  height="40"
                  viewBox="0 0 39 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.0202 0.666626H7.97491C3.65875 0.666626 0.164246 4.16113 0.164246 8.47729V31.5275C0.164246 35.8388 3.65875 39.3333 7.97491 39.3333H31.0251C35.3364 39.3333 38.8357 35.8388 38.8357 31.5226V8.47729C38.8309 4.16113 35.3364 0.666626 31.0202 0.666626Z"
                    fill="url(#paint0_linear_31_60)"
                  />
                  <path
                    d="M19.3333 9.55031L20.1163 8.19697C20.5996 7.35114 21.6774 7.06597 22.5233 7.5493C23.3691 8.03264 23.6543 9.11047 23.1709 9.95631L15.6261 23.016H21.0829C22.8519 23.016 23.8428 25.0943 23.0743 26.5346H7.07592C6.09959 26.5346 5.31659 25.7516 5.31659 24.7753C5.31659 23.799 6.09959 23.016 7.07592 23.016H11.5613L17.3033 13.0641L15.5101 9.95147C15.0268 9.10564 15.3119 8.03747 16.1578 7.54447C17.0036 7.06114 18.0718 7.34631 18.5648 8.19214L19.3333 9.55031ZM12.5473 28.3471L10.8556 31.281C10.3723 32.1268 9.29442 32.412 8.44859 31.9286C7.60276 31.4453 7.31759 30.3675 7.80092 29.5216L9.05759 27.3466C10.4786 26.9068 11.6338 27.2451 12.5473 28.3471V28.3471ZM27.1149 23.0256H31.6921C32.6684 23.0256 33.4514 23.8086 33.4514 24.785C33.4514 25.7613 32.6684 26.5443 31.6921 26.5443H29.1498L30.8656 29.5216C31.3489 30.3675 31.0638 31.4356 30.2179 31.9286C29.3721 32.412 28.3039 32.1268 27.8109 31.281C24.9206 26.2688 22.7504 22.5181 21.3101 20.0193C19.8359 17.477 20.8896 14.925 21.9288 14.0598C23.0839 16.0415 24.8094 19.0333 27.1149 23.0256V23.0256Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_31_60"
                      x1="19.4976"
                      y1="0.666626"
                      x2="19.4976"
                      y2="39.3333"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0ED4FC" />
                      <stop offset="1" stopColor="#0C57EF" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>App Store</span>
              </a>
              <a href="!#" className="btn btn--gradient">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34.8303 16.1824L7.53395 0.579574C6.87065 0.200362 6.11596 0 5.35184 0C4.57777 0 3.81473 0.20534 3.1452 0.593796C1.79404 1.37764 0.954727 2.83493 0.954727 4.39711V35.6029C0.954727 37.1649 1.79404 38.6222 3.14484 39.406C3.81437 39.7948 4.5776 40.0002 5.35166 40.0002C6.11542 40.0002 6.87011 39.7996 7.53378 39.4204L34.8297 23.8175C36.1962 23.0363 37.0449 21.5737 37.0449 19.9999C37.0449 18.4262 36.1965 16.9636 34.8303 16.1824ZM23.0018 12.493L19.254 17.7134L10.2892 5.2263L23.0018 12.493ZM5.17975 37.3181C4.93601 37.2945 4.69689 37.2234 4.48337 37.0995C3.9518 36.791 3.62148 36.2175 3.62148 35.6029V4.39694C3.62148 3.78234 3.9518 3.20881 4.48355 2.90036C4.69635 2.7768 4.93352 2.7032 5.17726 2.67813L17.6125 19.9999L5.17975 37.3181ZM10.2892 34.7737L19.254 22.2864L23.0018 27.5068L10.2892 34.7737ZM33.5067 21.502L25.3294 26.1763L20.8953 19.9999L25.3294 13.8235L33.5067 18.4978C34.0443 18.8052 34.3783 19.3807 34.3783 19.9999C34.3783 20.6191 34.0445 21.1948 33.5067 21.502Z"
                    fill="#9C37FD"
                  />
                </svg>
                <span>Play Store</span>
              </a>
            </div>
          </div>
          <div className="footer__right">
            <div className="checkbox footer__darkmode">
              <input onChange={e=>toggleTheme()} type="checkbox" checked={theme=="dark"?true:false} name="darkmode" id="darkmode" />
              <label htmlFor="darkmode">Dark theme</label>
            </div>
            <ul className="footer__socialicons">
              <li>
                <a href="https://linkedin.com">
                  <FiLinkedin />
                </a>
              </li>
              <li>
                <a href="https://twitter.com">
                  <FiTwitter />
                </a>
              </li>
              <li>
                <a href="https://instagram.com">
                  <FiInstagram />
                </a>
              </li>
              <li>
                <a href="https://www.com">
                  <FiGlobe />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
