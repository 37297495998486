import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { FiDownload, FiSend, } from "react-icons/fi";
import { FcAcceptDatabase, FcInfo } from "react-icons/fc";
import { BsHash, BsCashCoin } from "react-icons/bs";
import { RiUser3Line } from "react-icons/ri";


import SiteLayout from "../components/layout/SiteLayout";
import Loading from "../components/loading";
import authHeader from "../services/auth-header";
import { baseURL, getUserFromToken } from "../util/utils";
import { toast } from "react-toastify";

const TradeHistory = () => {
  const [myassets, setMyAssets] = useState([])
  const [myTx, setMyTx] = useState([])
  const [loading, setLoading] = useState(true)
  const [txsLoaded, setTxsLoaded] = useState(5)
  const [sendAmount, setSendAmount] = useState<any>()
  const [enableSendComponent, setEnableSendComponent] = useState(false)
  const [sendTokenDetails, setSendTokenDetails] = useState<any>()
  const [receiverAddress, setReceiverAddress] = useState('')

  useEffect(() => {
    fetchData()
  }, [])
  const transferToken = async (e: any) => {
    e.preventDefault()
    setEnableSendComponent(false)
    toast(
      <div style={{ display: "flex" }} >
        <div style={{ width: '100%' }}>
          <p>Are you sure to transfer {sendAmount} {sendTokenDetails.symbol}  </p>
          <div>
            <button className="btn " type="submit" style={{ padding: '5px 10px' }} onClick={e => transfer()} >Yes</button>
            <button className="btn btn--outline" style={{ padding: '5px 10px' }} onClick={e => { toast.dismiss();setInitiateValue() }}  > Cancel</button>
          </div>
        </div>
      </div>,
      { autoClose: false, position: 'top-center', draggable: false, progress: (false).toString() }
    )
  }
  const transfer = async () => {
    toast.dismiss()
    toast(
      <div style={{ display: "flex" }} >
        <div>
          <Loading width={30} />
        </div>
        <div  style={{ width: '100%' }}>
          <p>Please Wait , Transferring {sendAmount} {sendTokenDetails.symbol} ... </p>
        </div>
      </div>,
      { autoClose: false, position: 'top-center', draggable: false, progress: (false).toString() }
    ) 
    axios.post(`${baseURL}/api/tokens/transfer`, {
      amount: sendAmount,
      tokenMID: sendTokenDetails._id,
      receipentAddress: receiverAddress
    },{headers:authHeader()})
      .then(resp => {
        fetchData()
        setInitiateValue()
        toast.dismiss()
        toast.success(resp.data.message, { autoClose: 2000 })
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        toast.dismiss()
        if (err.response) {
          toast.error(err.response?.data?.message)
        }
      })
  }
  const fetchData = async () => {
    var user: any = await getUserFromToken()
    const myTxs = await axios.get(`${baseURL}/api/transection/my-tx/${user.user.id}`, { headers: authHeader() })
    const myAssets = await axios.get(`${baseURL}/api/offers/getAssets`, { headers: authHeader() })
    setMyAssets(myAssets.data)
    setMyTx(myTxs.data) 
    setLoading(false)
  }
  const loadMoretxs = () => {
    setTxsLoaded(txsLoaded + 5)
  }
  const isISent = (from: any) => {
    var tokenUser: any = getUserFromToken() 
    if (tokenUser?.user?.id == from?._id) {
      return true
    } else {
      return false
    }
  }
  const setInitiateValue= ()=>{
    setEnableSendComponent(false);
    setSendTokenDetails({});
    setReceiverAddress('');
    setSendAmount(0)
  }
  return (
    <SiteLayout variant="withoptions">
      <div className="tradehistory">
        {
          enableSendComponent &&
          <form onSubmit={e => { transferToken(e); setEnableSendComponent(false) }} className="send_modal tradestart__info " >
            <div className="send_modal_title">
              <h5> Transfer Token </h5>
            </div>
            <div className="send_modal_body">
              <div className="register__field">
                <label htmlFor="">Enter Receiver Wallet UID </label>
                <div className="inputbox">
                  <RiUser3Line />
                  <input required value={receiverAddress} type="text" style={{ height: '40px', fontSize: '14px' }} placeholder="Enter wallet Address" name="uid" onChange={e => setReceiverAddress(e.target.value)} />
                </div>
              </div>
              <div className="register__field">
                <label htmlFor="">Enter Token Amount [ 0-{sendTokenDetails.balance} {sendTokenDetails?.symbol}  ]</label>
                <div className="inputbox">
                  <BsCashCoin />
                  <input required value={sendAmount} type="number" style={{ height: '40px', fontSize: '14px' }} max={sendTokenDetails.balance} min={1} placeholder="Enter Amount" name="amount" onChange={e => setSendAmount(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="send_modal_footer">
              <button className="btn " type="submit" style={{ padding: '5px 10px' }} >Send</button>
              <button className="btn btn--outline" style={{ padding: '5px 10px' }} onClick={e => {
                setInitiateValue()
              }} > Cancel</button>
            </div>
          </form>
        }
        <div className="blobbg tradehistory__blob"></div>
        <div className="container container--small">
          <div className="tradehistory__inner">
            <h1 className="font-medium">Assets</h1>
            <div className="tradehistory__assets">
              <div className="table-responsive" >
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-left">Currency</th>
                      <th className="text-left">Balance</th>
                      <th className="text-center">In USD</th>
                      <th className="text-center"></th>
                      {/* <th className="text-right"></th> */}
                    </tr>
                  </thead>
                  <tbody>

                    {
                      loading ?
                        <>
                          <tr>
                            <td colSpan={4} >
                              <div className="text-center" >
                                <Loading width={30} />
                                <p> Your Assets Loading...  </p>
                              </div>
                            </td>
                          </tr>
                        </> : ''
                    }
                    {
                      myassets.map((token: any) => (
                        <tr>
                          <td className="text-left">
                            <div className="currency">
                              <div className="image">
                                <img
                                  src={`${baseURL}/${token.logo}`}
                                  alt="Ethereum"
                                />
                              </div>
                              <div className="content">
                                <p>{token.name}</p>
                                <small> {token.chain} </small>
                                {/* <small className="tiny-text">1 ETH = 14754 USD</small> */}
                              </div>
                            </div>
                          </td>
                          <td className="text-left">
                            <p className="font-semibold">{token.balance} {token.symbol}</p>
                          </td>
                          <td className="text-center">
                            <p>$_ _</p>
                          </td>
                          <td className="text-center">
                            <button onClick={e => { setSendTokenDetails(token); setEnableSendComponent(!enableSendComponent); }} >
                              <FiSend />
                            </button>
                          </td>
                          {/* <td className="text-right">
                        <button className="danger">
                          <FiDownload />
                        </button>
                      </td> */}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <br />
            <h1 className="font-medium">Latest Transactions</h1>
            <div className="tradehistory__transactions">
              <div className="table-responsive noborder">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-left">Transactions</th>
                      <th className="text-center">Details</th>
                      <th className="text-center">Status</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>


                    {
                      loading ?
                        <>
                          <tr>
                            <td colSpan={4} >
                              <div className="text-center" >
                                <Loading width={30} />
                                <p> Your Transections Loading...  </p>
                              </div>
                            </td>
                          </tr>
                        </> : ''
                    }
                    {
                      myTx.map((tx: any) => (
                        <>
                          {
                            isISent(tx.from) ?
                              <tr>
                                <td className="text-left">
                                  <div className="type">
                                    <div className="icon success">
                                      <FiSend />
                                    </div>
                                    <div className="content">
                                      <p>Sent Out </p>
                                      <span className="tiny-text">{(moment(tx.createdAt)).format("MMM DD, YYYY")}</span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <small>Sent  {tx?.value} {tx?.currency} to {tx?.to?.name} by Trade</small>
                                </td>
                                <td className="text-center">
                                  <span className="tag tag--success">Complete</span>
                                </td>
                                <td className="text-right">
                                  <p> {tx.value} {tx.currency} </p>
                                  {/* <small>-745.25 USD</small> */}
                                </td>
                              </tr> :
                              <tr>
                                <td className="text-left">
                                  <div className="type">
                                    <div className="icon success">
                                      <FcAcceptDatabase />
                                    </div>
                                    <div className="content">
                                      <p>Received</p>
                                      <span className="tiny-text"> {(moment(tx.createdAt)).format("MMM DD, YYYY")} </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <small>Received  {tx.value} {tx.currency}
                                  {/* from {tx.from?.name} */}
                                   </small>
                                </td>
                                <td className="text-center">
                                  <span className="tag tag--success">Complete</span>
                                </td>
                                <td className="text-right">
                                  <p> {tx.value} {tx.currency} </p>
                                  {/* <small>-745.25 USD</small> */}
                                </td>
                              </tr>
                          }
                        </>
                      ))
                    }
                  </tbody>
                </table>
                <div className="profileoffers__showmore">
                  <button className="btn" onClick={e => loadMoretxs()} >Show More</button>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default TradeHistory;
