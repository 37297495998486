import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Register from "./pages/Register";
import Login from "./pages/Login";
import "./App.scss";
import Createoffer from "./pages/Createoffer";
import Offers from "./pages/Offers";
import ProfilePage from "./pages/Profile";
import AccountSettings from "./pages/AccountSettings";
import TradeHistory from "./pages/TradeHistory";
import Transactions from "./pages/Transactions";
import BuyConfirmation from "./pages/BuyConfirmation";
import TradeStart from "./pages/TradeStart";
import ResearchPage from "./pages/ResearchPage";
import Coinpage from "./pages/Coinpage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddToken from "./pages/AddToken";
import PrivateRoutes from "./PrivateRoute";
import { baseURL, frontendSocket, isAuthenticated } from "./util/utils";
import axios from "axios";
import authHeader from "./services/auth-header";
import { useRecoilState } from 'recoil'
import { appState, authAppState,  userState } from "./state/state";
import OfferDetails from "./pages/OfferDetails";
import jwtDecode from "jwt-decode"; 
import Varify from "./pages/Varify";

const App: React.FC = () => {
  const [getUser, setUser] = useRecoilState(userState)
  const [getAppState, setAppState] = useRecoilState(appState)
  const [getAuthAppState, setAuthAppState] = useRecoilState(authAppState)
  useEffect(() => {
    // mode
    const mode= window.localStorage.getItem("mode")
    if(mode=="light_mode"){
      document.body.classList.add('light_mode');
    }

    
    
    
    var token = window.localStorage.getItem("user")
    if (token) {
      var decoded: any = jwtDecode(`${token}`)
      var notificationChannel = frontendSocket.subscribe("Notification"); 
      notificationChannel.bind("Deposit", function (data: [String]) {
        console.log("get deposit notificatoin ")
        getAuthData()
      });
      notificationChannel.bind("Withdraw",function (data:any){ 
        if(data[0]==decoded.user.id){
          getAuthData()
        }
      })
    }
    async function getData() {
      await getAuthData()
      await getPublicData()
    }
    getData()
  }, [])
  const getAuthData = async () => {
    if (isAuthenticated()) {
      var users = await axios.get(`${baseURL}/api/users/me`, { headers: authHeader() })
      setUser({ ...getUser, ...users.data, loading: false })

      var notifications = await axios.get(`${baseURL}/api/users/notifications`, { headers: authHeader() })
      setAuthAppState({ ...getAuthAppState, notifications: notifications.data })

    }
    return;
  }
  const getPublicData = async () => {
    var tokens = await axios.get(`${baseURL}/api/tokens/getAllTokens`)
    setTimeout(() => {
      setAppState({ ...getAppState, tokens: tokens.data, loading: false })
    }, 1000);
  }
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/varify" element={<Varify />} />
          <Route path="/login" element={<Login />} />

          {/* Private routes */}
          <Route element={<PrivateRoutes />} >
            <Route path="/offers" element={<Offers />} />
            <Route path="/addToken" element={<AddToken />} />
            <Route path="/confirmbuy/:offerId" element={<BuyConfirmation />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/accountsetting" element={<AccountSettings />} />
            <Route path="/tradehistory" element={<TradeHistory />} />
            <Route path="/createoffer" element={<Createoffer />} />
            <Route path="/offer-details/:offerId" element={<OfferDetails />} />

            {/* Next milestone */}
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/tradestart" element={<TradeStart />} />
            <Route path="/coinpage" element={<Coinpage />} />

            {/* In future */}
            <Route path="/research" element={<ResearchPage />} />

          </Route>

        </Routes>
      </Router>
    </>
  );
}

export default App;
