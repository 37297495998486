import clsx from "clsx";
import { useState } from "react";
import { TSelectItem } from "../../globalTypes";
import { baseURL } from "../../util/utils";

type TSectionBoxProps = {
  data: any;
  currentData: any;
  changeHandler: (coin: TSelectItem) => void;
  alwaysOpen?: boolean;
};

const SelectionBox = ({
  data,
  currentData,
  changeHandler,
  alwaysOpen
}: TSectionBoxProps) => {
  const [openList, setOpenList] = useState<boolean>(false);

  const handleItemClick = (item: TSelectItem) => {
    changeHandler(item);
    setOpenList(false);
  };

  const listClass = clsx(
    "selectionbox__list",
    alwaysOpen && "selectionbox__list--alwaysopen",
    openList && "selectionbox__list--open"
  );

  return (
    <div className="selectionbox">
      <div
        className="selectionbox__field"
        onClick={() => setOpenList(!openList)}
      >
        {currentData.chain && (
          <span className="selectionbox__icon">
            <img src={`${baseURL}/${currentData.logo}`} alt="" />
          </span>
        )}
        {currentData.name}
        <span className="selectionbox__caret">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 6.75L9 11.25L13.5 6.75"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className={` ${listClass}`}>
        <ul>
          {data?.length &&
            data.map((item: any) => (
              <li onClick={() => handleItemClick(item)} key={item.address}>
                <img src={`${baseURL}/${item.logo}`} style={{ width: '18px' }} alt="" />
                {` ${item.name}`}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectionBox;
