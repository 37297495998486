import SectionTititle from "../items/SectionTititle";
import Testimonial from "../items/Testimonial";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <span className="blobbg testimonials__blob"></span>
      <div className="container container--small">
        <SectionTititle
          title="Testimonials"
          subtitle="Wondering what our users think about using our platform? Let them tell you in their own words:"
        />
        <div className="testimonials__inner">
          <Testimonial
            image="/images/testimonial-image-1.png"
            content="Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Lorem ipsum dolor sit amet"
          />
          <Testimonial
            image="/images/testimonial-image-2.png"
            content="Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Lorem ipsum dolor sit amet"
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
