import { useState } from "react";
import { FiMail, FiUser } from "react-icons/fi";
import { BiImageAdd } from "react-icons/bi";
import { TSelectItem } from "../globalTypes";
import SelectionBox from "../components/items/SelectionBox";
import SiteLayout from "../components/layout/SiteLayout";
import { useDropzone } from "react-dropzone";
import { useRecoilState } from 'recoil'
import { userState } from "../state/state";
import { baseURL, getLastActiveTime } from "../util/utils";
import TimezoneSelectionBox from "../components/items/TimezoneSelectionBox";
import axios from "axios";
import authHeader from "../services/auth-header";
import { toast } from "react-toastify";
import Loading from "../components/loading"; 
const timeZones = [
  {
    id: 0,
    title: "(GMT+05) Eastern Time Zone",
    value: "GMT+5",
  },
  {
    id: 1,
    title: "(GMT+06) Eastern Time Zone",
    value: "GMT+6",
  },
  {
    id: 2,
    title: "(GMT+07) Eastern Time Zone",
    value: "GMT+7",
  },
];

const AccountSettings = () => {
  const [user, setUser] = useRecoilState(userState)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [bio, setBio] = useState("")
  const [timeZone, setTimeZone] = useState<any>()
  const [notification, setNotification] = useState(false)
  const [activeTimezone, setActiveTimezone] = useState(timeZones[0]);
  const [toastID, setToastID] = useState <any> (null)
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      maxFiles: 1,
      maxSize: 2097152,
    });
  const handleActiveTimezone = (value: TSelectItem) => {
    console.log(value)
    setActiveTimezone(value);
    setTimeZone(value.id)
    console.log(value.id)
  };
  const setValues = () => {
    if (name) return
    if (user._id) {
      setName(user.name)
      setEmail(user.email)
      setBio(user.bio)
      setTimeZone(user.timeZone)
      setNotification(user.notification)
      setActiveTimezone(timeZones[user.timeZone]?timeZones[user.timeZone]:timeZones[0])
    }
  }
  const submitHandler = async(e: any) => {
    e.preventDefault()
    var body = new FormData()
    console.log(">>>>>>>>", timeZone)
    body.append("name",name) 
    body.append("email",email) 
    body.append("bio",bio) 
    body.append("timeZone",timeZone) 
    body.append("notification",notification?.toString()) 
    body.append("activeTimezone",activeTimezone.toString()) 
    body.append("file", acceptedFiles[0])
    setToastID(toast(<div> 
      <Loading width={25} /> <span> Your Profile Updating ... </span> 
       </div>, {icon:false, position:'top-center', progress:(false).toString()}))
    var updatedUser = await axios.post(`${baseURL}/api/users/updateProfile`,body,{headers:authHeader()})
    setUser( { ...user , ...updatedUser.data})
    setTimeout(() => {
      toast.dismiss(toastID)
      setToastID(null)
    }, 2000);

  }
  return (
    <SiteLayout variant="withoptions">
      <div className="acount">
        <div className="blobbg account__blob"></div>
        <div className="account">
          <>
            {setValues()}
          </>
          <div className="container container--small">
            <div className="account__inner">
              <div className="account__head">
                <h1>Account settings</h1>
                <p>{getLastActiveTime(user.lastSeen)}</p>
              </div>
              <form onSubmit={e => submitHandler(e)} className="account__form">
                <div className="account__formfield account__formfield--small">
                  <label htmlFor="">Your username</label>
                  <div className="inputbox">
                    <FiUser />
                    <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder={user.name ? user.name : "Enter user name"} />
                  </div>
                </div>
                <br />
                <div className="account__formfield account__formfield--small">
                  <label htmlFor="">Your e-mail</label>
                  <div className="inputbox">
                    <FiMail />
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder={user.email ? user.email : "Enter your e-mail"} />
                  </div>
                </div>
                <br />
                <div className="account__formfield">
                  <label htmlFor="">Bio</label>
                  <div className="inputbox">
                    <textarea
                      name=""
                      value={bio}
                      id=""
                      cols={5}
                      rows={9}
                      onChange={e => setBio(e.target.value)}
                      placeholder="Enter your bio here"
                    ></textarea>
                  </div>
                </div>
                <br />
                <div className="account__formfield account__formfield--small">
                  <div className="uploadbox" {...getRootProps()}>
                    {acceptedFiles && acceptedFiles.length ? (
                      <div className="uploadbox__image">
                        <img
                          src={URL.createObjectURL(acceptedFiles[0])}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="uploadbox__upload">
                        <input  {...getInputProps()} />
                        <BiImageAdd />
                        <p>Upload your image</p>
                      </div>
                    )}
                  </div>
                  <p className="error">
                    {fileRejections[0]?.errors[0]?.message}
                  </p>
                </div>
                <br />
                <div className="account__formfield account__formfield--small">
                  <label htmlFor="">Choose your time zone</label>
                  <>{console.log( "ac rajaji",  activeTimezone)}</>
                  <TimezoneSelectionBox
                    data={timeZones}
                    currentData={activeTimezone}
                    changeHandler={handleActiveTimezone}
                  />
                </div>
                <br />
                <br />
                <div className="account__formfield">
                  <div className="checkbox">
                    <input type="checkbox" checked={notification} onChange={e => setNotification(!notification)} name="" id="notification" />
                    <label htmlFor="notification">Notification</label>
                  </div>
                </div>
                <div style={{ marginTop: '15px' }}>
                  <button type="submit" className="btn btn--outline">  Update  Profile </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default AccountSettings;
