import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appState, userState } from "../../state/state";
import { baseURL } from "../../util/utils";
import SelectionBox from "../items/SelectionBox";
import Loading from "../loading";

const coinData = [
  {
    id: 1,
    icon: "/icons/ethereum.svg",
    title: "Ethereum",
    value: "Ethereum",
    subtitle: "ETH",
  },
  {
    id: 2,
    icon: "/icons/tether.svg",
    title: "Binance",
    value: "Binance",
    subtitle: "BUSD",
  }, 
];

const Herosection = () => {
  const navigate = useNavigate();
  const [getAppState, setAppState] = useRecoilState(appState)
  const [isDataSattled, setIsDataSattled] = useState(false)

  const [activeForm, setActiveForm] = useState<"buy" | "sell">("buy");
  const [buyCoinType, setBuyCoinType] = useState<any>(coinData[0]);
  const [payCoinType, setPayCoinType] = useState<any>(coinData[1]);
  const [amount, setAmount] = useState <any> ()

  const handleBuyCoinType = (coin: any) => {
    setBuyCoinType(coin);
  };

  const handlePayCoinType = (coin: any) => {
    setPayCoinType(coin);
  };

  const handleFindOffer = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate(`/offers?action=${activeForm}&paytoken=${payCoinType.address}&buytoken=${buyCoinType.address}&amount=${amount}`);
  };

  const setData = () => {
    if (getAppState.tokens?.length > 0 && !isDataSattled) {
      handlePayCoinType(getAppState.tokens[0])
      handleBuyCoinType(getAppState.tokens[1])
      setIsDataSattled(true)
    }
  }
  return (
    <div className="herosection">
      <div className="container">
        <>
          {setData()}
        </>
        <div className="herosection__inner">
          <h1>NON OMNIS MORIAR</h1>
          {
            getAppState.loading ?
              <div className="text-center">
                <Loading width={40} /> 
                <p>Loading...</p>
              </div> :
              <div className="buysellbox">
                <div className="buysellbox__top">
                  <button
                    className={`btn ${activeForm === "buy" ? "" : "btn--outline"}`}
                    onClick={() => setActiveForm("buy")}
                  >
                    Buy
                  </button>
                  <button
                    className={`btn ${activeForm === "sell" ? "" : "btn--outline"}`}
                    onClick={() => setActiveForm("sell")}
                  >
                    Sell
                  </button>
                </div>
                <form className="buysellbox__form">
                  <div className="buysellbox__field">
                    <label>Buy</label>
                    <SelectionBox
                      data={getAppState.tokens}
                      currentData={buyCoinType}
                      changeHandler={handleBuyCoinType}
                    />
                  </div>
                  <div className="buysellbox__field">
                    <label>Pay With</label>
                    <SelectionBox
                      data={getAppState.tokens}
                      currentData={payCoinType}
                      changeHandler={handlePayCoinType}
                    />
                  </div>
                  <div className="buysellbox__field">
                    <label htmlFor="">I want to {activeForm=="sell"?"Sell":"Spend"}  </label>
                    <div className="inputbox">
                      <img src={` ${baseURL}/${payCoinType.logo}`} alt="icon" />
                      <input
                      onChange={e=>setAmount(e.target.value)}
                        type="text"
                        placeholder={`Enter amount (${payCoinType.symbol})`}
                      />
                    </div>
                    <p className="message">Minimum: 1 ({payCoinType.symbol})</p>
                  </div>
                </form>
                <div className="buysellbox__bottom">
                  <button className="btn" onClick={(e) => handleFindOffer(e)}>
                    Find  offer
                  </button>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Herosection;
