import React, { useEffect, useState } from "react";
import { FiExternalLink, FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import SiteLayout from "../components/layout/SiteLayout";
import { useParams } from 'react-router-dom'
import axios from "axios";
import authHeader from "../services/auth-header";
import { toast, useToast } from "react-toastify";
import { baseURL, getLastActiveTime } from "../util/utils";
import { useRecoilState } from "recoil"
import { userState } from "../state/state";
import Loading from "../components/loading";
import { FcClock } from "react-icons/fc";
import moment from 'moment';
import { getTotalSoldAmount } from "../util/constant";

const BuyConfirmation = () => {
  const [user, setUser] = useRecoilState(userState)

  const [payToken, setPayToken] = useState<number>(0) //set minAmouont
  const { offerId } = useParams();
  const [offer, setOffer] = useState<any>({});
  const [enableTrade, setEnableTrade] = useState(false)
  const [depositing, setDepositing] = useState(false)
  const [buyAmount, setBuyAmount] = useState(0)

  const fetchOffers = () => {
    return axios.get(`${baseURL}/api/offers/getOfferById/${offerId}`, { headers: authHeader() })
      .then((resp) => {
        console.log("hi offer ", resp.data.offer)
        setOffer(resp.data.offer);
        var currentOffer = resp.data.offer
        setPayToken(currentOffer.minAmount)
        var buy_amount = (currentOffer.maxAmount - getTotalSoldAmount(currentOffer.soldRecord)) >= currentOffer.minAmount ? currentOffer.minAmount : (currentOffer.maxAmount - getTotalSoldAmount(currentOffer.soldRecord))
        setBuyAmount(buy_amount)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchOffers();
  }, []);

  const handleConfirm = (e: any) => {
    e.preventDefault()
    if (buyAmount < 1) {
      return toast.error("You can't buy 0 token ")
    }
    toast(
      <div style={{ display: "flex" }} >
        <div>
          <Loading width={30} />
        </div>
        <div>
          Are you sure you want to make trade ?
          <br />
          <button className="btn " style={{ padding: '5px 10px' }} onClick={e => { trade() }}>Yes</button>
          <button className="btn btn--outline" style={{ padding: '5px 10px' }}  > Cancel</button>
        </div>
      </div>,
      { autoClose: false, position: 'top-center', draggable: false, progress: (false).toString() }
    )
  };
  const trade = () => {
    if (offer.user._id == user._id) return toast.error("You can't trade with your own offer", { autoClose: 2500, position: 'top-center' })
    const currentTime = moment();
    const offerTime = moment(offer?.createdAt).add(offer?.timeLimit, "minutes")

    // if (offerTime.isBefore(currentTime)) {
    //   return toast.error('Time limit expired !!');
    // } 

    console.log("calling buyerdeposit")
    setDepositing(true)
    axios.post(`${baseURL}/api/offers/buyerDeposit`, {
      buyAmount: buyAmount,
      offerMID: offer._id
    }, { headers: authHeader() })
      .then((resp) => {
        setDepositing(false)
        setEnableTrade(true)
        toast.success(resp.data.message, { autoClose: 1500, position: "top-center" })
      })
      .catch(err => {
        setDepositing(false)
        toast.error(err?.response?.data?.message, { autoClose: 5000 })
        console.log(err)
      })
  }
  return (
    <SiteLayout variant="withoptions">
      <div className="confirmbuy">
        <div className="container container--small">
          <div className="confirmbuy__inner">
            <h2 className="font-display text-center">
              Buy <u>{offer.sellToken?.name}</u> with <u>{offer.buyToken?.name}</u> - instant confirmation
            </h2>
            <br />
            <>{
              enableTrade ?
                <>
                  <h4 className="text-center"> Our Order is confirmed  </h4>
                  <div className="buyform__formfields">
                    <div className="buyform__field submitbtn text-center">
                      <p>Check Offer status <Link to={"/profile"} style={{ color: 'white', textDecoration: 'underline' }} > Here  </Link> </p>
                    </div>
                  </div>
                </> :
                <>
                  {
                    depositing ?
                      <>
                        <h4 className="text-center"> Please wait, your transection in progress.  </h4>
                        <div className="text-center">
                          <img src="/images/loading.gif" style={{ width: "50px" }} />
                        </div>
                      </> :
                      <form className="buyform" onSubmit={e => handleConfirm(e)}>

                        <div className="text-center">
                          <p>Time Limit </p>
                          <p><FcClock /> You can make trade till {(moment(offer?.createdAt).add(offer?.timeLimit, "minutes")).format("HH:mm:ss A")}   </p>
                        </div>
                        <h4 className="text-center">How much do you want to Buy  ?</h4>
                        <div className="buyform__formfields">
                          <div className="buyform__field">
                            <label htmlFor="">I want to buy <span style={{ fontWeight: "600", color: '#7C3AED' }} > {(offer.maxAmount - getTotalSoldAmount(offer.soldRecord)) >= buyAmount ? buyAmount : (offer.maxAmount - getTotalSoldAmount(offer.soldRecord))} </span> of {offer.maxAmount - getTotalSoldAmount(offer.soldRecord)} {offer.sellToken?.symbol}  </label>
                            <div className="inputbox">
                              <img src={`${baseURL}/${offer.sellToken?.logo}`} alt="icon" />
                              <input max={offer.maxAmount - getTotalSoldAmount(offer.soldRecord)} type="number" min={(offer.maxAmount - getTotalSoldAmount(offer.soldRecord)) >= buyAmount ? buyAmount : (offer.maxAmount - getTotalSoldAmount(offer.soldRecord))} value={(offer.maxAmount - getTotalSoldAmount(offer.soldRecord)) >= buyAmount ? buyAmount : (offer.maxAmount - getTotalSoldAmount(offer.soldRecord))} onChange={e => setBuyAmount(parseInt(e.target.value))} />
                            </div>
                            <span>Max Amount : {offer.maxAmount - getTotalSoldAmount(offer.soldRecord)}  {offer.sellToken?.symbol} </span>
                          </div>
                          <div className="buyform__field">
                            <label htmlFor="">Pay Amount :  {buyAmount * offer.price} {offer.buyToken?.symbol} </label>
                            <>{console.log("payamount ", buyAmount)}</>
                            <div className="inputbox">
                              <img src={`${baseURL}/${offer.buyToken?.logo}`} alt="icon" />
                              <input type="number" disabled placeholder={`${offer.price * buyAmount} ${offer.buyToken?.symbol} `} />
                            </div>
                          </div>
                          <div className="buyform__field submitbtn text-center">
                            <button type="submit" className="btn">
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </form>
                  }
                </>
            }
            </>
            <br />
            <div className="confirmbuy__about">
              <div className="aboutoffer">
                <h5>About This offer</h5>
                <div className="aboutoffer__box">
                  <div className="aboutoffer__sellerrate">
                    <h6 className="title">Seller rate</h6>
                    <h5>
                      0.03 ETH
                      <span className="success">0.11% above market</span>
                    </h5>
                  </div>
                  <div className="aboutoffer__buylimits">
                    <h6 className="title">Buy limits</h6>
                    <h5>
                      <span>Min</span> -{offer.minAmount} {offer.buyToken?.symbol}
                    </h5>
                    <h5>
                      <span>Max</span> -{offer.maxAmount} {offer.sellToken?.symbol}
                    </h5>
                  </div>
                  <div className="aboutoffer__limitfee">
                    <div className="aboutoffer__limit">
                      <h6 className="title">Trade time limit</h6>
                      <h5> {offer.timeLimit} min </h5>
                    </div>
                    <div className="aboutoffer__fee">
                      <h6 className="title">NOM fee</h6>
                      <h5>1.03%</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutbuyer">
                <h5>About this Seller</h5>
                <div className="aboutbuyer__box">
                  <div className="aboutbuyer__inner">
                    <div className="aboutbuyer__top">
                      <div className="image">
                        <img
                          src={`${baseURL}/${offer.user?.pp}`}
                          alt=""
                          className="aboutbuyer__image"
                        />
                      </div>
                      <div className="content">
                        <h5>
                          {offer.user?.name}
                          <Link to="/profile">
                            <FiExternalLink />
                          </Link>
                        </h5>
                        <small>{getLastActiveTime(offer.user?.lastSeen)} </small>
                      </div>
                    </div>
                    <div className="aboutbuyer__content">
                      <div className="aboutbuyer__feedbacks">
                        <div className="aboutbuyer__feedback">
                          <div className="aboutbuyer__feedback-icon">
                            <FiThumbsUp />
                          </div>
                          <h5>+{offer.user?.upVote} </h5>
                          <small>Positive feedback</small>
                        </div>
                        <div className="aboutbuyer__feedback">
                          <div className="aboutbuyer__feedback-icon">
                            <FiThumbsDown />
                          </div>
                          <h5>-{offer.user?.downVote} </h5>
                          <small>Negative feedback</small>
                        </div>
                      </div>
                    </div>
                    <div className="aboutbuyer__bottom">
                      <p>Average trade speed</p>
                      <h5>Instant</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="confirmbuy__offerterms">
            <h5>Offer terms</h5>
            {
              offer.splitedTrade ?
                <div className="">
                  <p> Any Amount Allowed </p>
                </div> :
                <p> Only Maximum Amount Allowed</p>
            }
            </div>
            <br />
            {
              offer.selectedOptions?.length > 0 ?
                <div>
                  <h4>Reason for Table</h4>
                  <ul>
                    {
                      offer.selectedOptions?.map((el: any) => {
                        return (
                          <li> {el} </li>
                        )
                      })
                    }
                  </ul>
                </div> :
                ''
            }

            <div className="text-right">
              <button className="btn">Report</button>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default BuyConfirmation;
