export type TTestimonialProps = {
  image: string;
  content: string;
};

const Testimonial = ({ image, content }: TTestimonialProps) => {
  return (
    <div className="testimonial">
      <div className="testimonial__image">
        <img src={image} alt="person" />
      </div>
      <div className="testimonial__content">
        <p>
          {content} <a href="!#">Read more...</a>
        </p>
      </div>
    </div>
  );
};

export default Testimonial;
