import React from 'react'

const Loading = (width:any) => {
    return (
        <>
            <img style={{ width:`${width.width}px` }} src="/images/loading.gif" />
        </>
    )
}

export default Loading