import { useState } from "react";
import { FiMail,  FiLock } from "react-icons/fi";
import SiteLayout from "../components/layout/SiteLayout";
import RegisterImage from "../assets/images/register-image.png";
import AuthService from "../services/auth.service"; 
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
 
const Login: React.FC  = () => {

  type Values = {
    email: string,
    password: string,
  }

  const [values, setValues] = useState<Values>({
    email: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    AuthService.login(
      values.email,
      values.password
    ).then(
      response => {
        toast.success('Successfully Logged In!',{autoClose:1500,position:'top-center'});
        console.log(response);
        if (response.token) {
          localStorage.setItem("user", JSON.stringify(response)); 
        }
        setTimeout(() => {
          window.location.href="/"
        }, 2000);
      },
      error => {
        console.log(error.response?.data)
        toast.error(error.response?.data.message,{autoClose:1500});
      }
    );
  }

  return (
    <SiteLayout variant="onlylogo" noFooter>
      <div className="register">
        <div className="container">
          <div className="register__inner">
            <div className="register__content">
              <h4 className="register__title">Sign In</h4>
              <form className="register__form" method="post" onSubmit={(e) => handleSubmit(e)}>
                <div className="register__field">
                  <label htmlFor="">Your E-mail</label>
                  <div className="inputbox">
                    <FiMail />
                    <input type="email" placeholder="Enter user e-mail" name="email" onChange={handleChange} />
                  </div>
                </div>
                <div className="register__field">
                  <label htmlFor="">Password</label>
                  <div className="inputbox">
                    <FiLock />
                    <input type="password" placeholder="Enter Password" name="password" onChange={handleChange} />
                  </div>
                </div>
                <div className="register__buttons">
                  <button className="btn" type="submit">
                    Sign In
                  </button>
                  <Link to={'/'}>
                    <button className="btn btn--outline" > Cancel</button>
                  </Link>
                </div>
                <p style={{marginTop:'15px'}} >Not  registered yet ?  <Link to={"/register"}  style={{color:'#7c3aed',textDecoration:'underline'}} >Go to Register</Link> </p>
              </form>
            </div>
            <div className="register__image">
              <img src={RegisterImage} alt="register" />
            </div>
          </div>
        </div>
        <span className="blobbg register__blob"></span>
      </div>
    </SiteLayout>
  );
};

export default Login;
