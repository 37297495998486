import { ReactNode } from "react";
import SiteFooter from "./SiteFooter";
import SiteHeader from "./SiteHeader";
import SiteHeaderOnlyLogo from "./SiteHeaderOnlyLogo";
import HerosectionBgShape from "../../assets/images/hrosection-bg-shape.png";
import SiteHeaderAlt from "./SiteHeaderAlt";
import { IUser, UserState } from "../../type";
import { shallowEqual, useSelector } from "react-redux";

type TSiteContentProps = {
  children: ReactNode;
  variant?: "default" | "onlylogo" | "withoptions";
  noFooter?: boolean;
};

const SiteLayout = ({
  children,
  variant = "default",
  noFooter,
}: TSiteContentProps) => {
  const user: any = useSelector(
    (state: UserState) => state.user,
    shallowEqual
  )
  return (
    <div className="sitewrapper">
      {variant === "default" && <SiteHeader user={user} />}
      {variant === "onlylogo" && <SiteHeaderOnlyLogo />}
      {variant === "withoptions" && <SiteHeaderAlt user={user}/>}
      <main className="pagecontent">
        <span className="blobbg"></span>
        <span className="bgshape">
          <img src={HerosectionBgShape} alt="shape" />
        </span>
        {children}
      </main>
      {!noFooter && <SiteFooter />}
    </div>
  );
};

export default SiteLayout;
