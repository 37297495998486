import Pusher from "pusher-js";
import jwtDecode from "jwt-decode";
import moment from "moment";
// Pusher.logToConsole=true

export const isAuthenticated = () => {
  if (window.localStorage.getItem('user')) {
    return true
  } else {
    return false
  }
}
export const activeMarketRate = {
  MARKET_PRICE: "MARKET_PRICE",
  RUGULAR_PRICE: "RUGULAR_PRICE"
}
export const baseURL = "https://api.nom.us"
// export const baseURL = "http://localhost"

export const getLastActiveTime = (lastActiveTime: any) => {
  const now = moment();
  const duration = moment.duration(now.diff(lastActiveTime));
  const minutes = duration.asMinutes();
  if (minutes < 5) {
    return "🟢 Active Now";
  } else if (minutes < 60) {
    return ("Seen " + (Math.floor(minutes) + " min ago"));
  } else {
    return ("Seen " + (Math.floor(duration.asHours()) + " hours ago"));
  }
}




export const getCreationTime = (lastActiveTime: any) => {
  const now = moment();
  const duration = moment.duration(now.diff(lastActiveTime));
  const hours = duration.asHours();
  if (hours >= 1) {
    return (Math.floor(hours) + " hours ago");
  } else {
    return (Math.floor(duration.asMinutes()) + " min ago");
  }
}
export const getRandomID = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let id = "";
  for (let i = 0; i < 8; i++) {
    id += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return id;
}

export const frontendSocket = new Pusher("6f320e55606c338bdbf7", {
  cluster: 'ap2'
});

export const getUserFromToken = () => {
  if (window.localStorage.getItem("user")) {
    return jwtDecode(`${window.localStorage.getItem("user")}`)
  } else return {}
}


export const getSuperRandomString = () => {
  const length = 8;
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}
