import React, { useState, useEffect, SyntheticEvent } from "react";
import SelectionBox from "../components/items/SelectionBox";
import authHeader from '../services/auth-header';
import SiteLayout from "../components/layout/SiteLayout";
import axios from "axios";


import { baseURL, isAuthenticated, frontendSocket, getRandomID } from "../util/utils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loading from "../components/loading";
import jwtDecode from 'jwt-decode'

const Createoffer: React.FC = () => {
  const [activeState, setActiveState] = useState<number>(1);
  const [sellCoinType, setSellCoinType] = useState({
    name: "",
    symbol: "",
    address: "",
    chain: "",
    file: ""
  });
  const [payCoinType, setPayCoinType] = useState({
    name: "",
    symbol: "",
    address: "",
    chain: "",
    file: ""
  });
  const [activeRate, setActiveRate] = useState<"marketprice" | "regularprice">(
    "regularprice"
  ); //1/2
  const [price, setPrice] = useState<number>(1);
  const [offerTradeLimit, setOfferTradeLimit] = useState<number>(5);
  const [offerTimeLimit, setOfferTimeLimit] = useState<number>(30);

  const [minAmount, setMinAmount] = useState<number>(10)
  const [maxAmount, setMaxAmount] = useState<number>(100)
  const [offerCreationError, setOfferCreationError] = useState({ message: '', help: [] })

  // optoinal
  const [offerLabel, setOfferLabel] = useState<string>()
  const [offerTerms, setOfferTerms] = useState([])
  const [offerInstruction, setOfferInstruction] = useState<string>()
  // tokens
  const [tokens, setTokens] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [offerCreationStatus, setOfferCreationStatus] = useState(0)
  //0 is nothing , 1 is under creation process , 2 is success , 3 is failed 
  const [doneSteps, setDoneSteps] = useState<number>(1)
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [splitedTrade, setSplitedTrade] = useState(true)


  useEffect(() => {
    var token = window.localStorage.getItem("user")
    if (token) {
      var decoded: any = jwtDecode(`${token}`)
      var channel = frontendSocket.subscribe("Offer");
      channel.bind("create", function (data: any) {
        if (decoded.user?.id == data.uid) {
          setDoneSteps(data.step)
        }
      });
    }
    setLoading(true)
    axios.get(`${baseURL}/api/tokens/getAllTokens`, { headers: authHeader() })
      .then(resp => {
        setTimeout(() => {
          setTokens(resp.data)
          if (resp.data?.length >= 2) {
            handleBuyCoinType(resp.data[0])
            handlePayCoinType(resp.data[1])
          }
          setLoading(false)
        }, 1000);
      })
      .catch(err => {
        console.log(err?.response?.data)
        setLoading(false)
      })
  }, [])

  const handleOptionToggle = (option: any) => {
    const index = selectedOptions.indexOf(option);
    if (index === -1) {
      // Option was not selected, add it to the array
      setSelectedOptions([...selectedOptions, option]);
    } else {
      // Option was already selected, remove it from the array
      setSelectedOptions([
        ...selectedOptions.slice(0, index),
        ...selectedOptions.slice(index + 1),
      ]);
    }
  };
  const handlePrev = () => {
    if (activeState !== 1) {
      setActiveState((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (activeState == 1) {
      if (!sellCoinType.address) return toast.error("Please select a Token you want to buy")
      if (!payCoinType.address) return toast.error("Please select Paying Token")
    }
    if (sellCoinType.address == payCoinType.address) {
      return toast.error("You can't trade for same token you paying", { autoClose: 2300 })
    }
    setActiveState((prev) => prev + 1);
  };

  const handleSubmit = () => {
    if (!isAuthenticated()) return toast.error("Please login your account before creating your offer", { position: 'top-center', autoClose: 1500 })
    setOfferCreationStatus(1)
    console.log("selecoption", selectedOptions)
    axios
      .post(baseURL + "/api/offers/registerOffer",
        {
          buyTokenName: payCoinType.name,
          buyTokenAddress: payCoinType.address,
          selectedOptions,
          splitedTrade,
          sellTokenName: sellCoinType.name,
          sellTokenAddress: sellCoinType.address,
          offerType: activeRate == "marketprice" ? 1 : 2,
          price: price,
          minAmount: minAmount,
          maxAmount: maxAmount,
          timeLimit: offerTimeLimit,
          offerLabel,
          offerTerms,
          offerInstruction,
          offerTradeLimit,
          offerTimeLimit,
        },
        {
          headers: authHeader(),
        })
      .then(response => {
        setOfferCreationStatus(2)
        if (response.data.res == 'success')
          toast.success('Offer Successfully Registered', { autoClose: 1500, position: 'top-center' });
      })
      .catch(err => {
        setOfferCreationStatus(3)
        setOfferCreationError(err?.response?.data)
        toast.error(err?.response?.data?.message, { autoClose: 3000 })
      })
  };

  const handleBuyCoinType = (coin: any) => {
    setSellCoinType(coin);
  };

  const handlePayCoinType = (coin: any) => {
    setPayCoinType(coin);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(event.target.value));
  };

  const handleOfferTradeLimitChange = (e: SyntheticEvent, value: number) => {
    e.preventDefault();
    if (value > 0) {
      setOfferTradeLimit(value);
    }
  };

  const handleOfferTimeLimitChange = (e: SyntheticEvent, value: number) => {
    e.preventDefault();
    if (value > 0) {
      setOfferTimeLimit(value);
    }
  };


  const handleDone = (stepsNumber: number) => {
    setDoneSteps(stepsNumber);
    // setDoneSteps((doneSteps) => doneSteps + 1);
  };
  return (
    <SiteLayout variant="withoptions">
      <div className="createoffer">
        <div className="blobbg createoffer__blob"></div>
        <div className="container">
          <div className="createoffer__inner">
            <div className="createoffer__left">
              {
                offerCreationStatus == 3 &&
                <div style={{ marginTop: '100px' }}>
                  <h4>Error occurd during creation !</h4>
                  <p> [{offerCreationError.message}] </p>
                </div>
              }
              {
                (offerCreationStatus == 1 || offerCreationStatus == 2) &&
                <div style={{ paddingBottom: '100px' }}>
                  <h2>Offer creation in Progress ... </h2>
                  <ul className="createoffer__steps createoffer__steps__progress  ">
                    <li className={doneSteps >= 1 ? "active" : ""}>
                      {
                        doneSteps == 1 ?
                          <span style={{ marginRight: '4px' }} > <Loading width={22} /> </span> :
                          <span className="circle"></span>
                      }
                      <span> Approving </span>
                    </li>
                    <li className={doneSteps >= 2 ? "active" : ""}>
                      {
                        doneSteps == 2 ?
                          <span style={{ marginRight: '4px' }} > <Loading width={22} /> </span> :
                          <span className="circle"></span>
                      }
                      <span>Creating  </span>
                    </li>
                    {/* <li className={doneSteps >= 3 ? "active" : ""}>
                      {
                        doneSteps == 3 ?
                          <span style={{ marginRight: '4px' }} > <Loading width={22} /> </span> :
                          <span className="circle"></span>
                      }
                      <span>Depositing </span>
                    </li> */}
                    <li className={doneSteps >= 3 ? "active" : ""}>
                      <span className="circle"></span>
                      <span>Finish </span>
                    </li>
                  </ul>
                  {
                    doneSteps >= 4 &&
                    <h4>Offer creation success</h4>
                  }
                </div>
              }
              {
                offerCreationStatus == 0 &&
                <>
                  <h2>Create an Offer to Sell  {sellCoinType.name} </h2>
                  <ul className="createoffer__steps">
                    <li className={activeState === 1 ? "active" : ""}>
                      <span className="circle"></span>
                      <span>Cryptocurrenty</span>
                    </li>
                    <li className={activeState === 2 ? "active" : ""}>
                      <span className="circle"></span>
                      <span>Pricing</span>
                    </li>
                    <li className={activeState === 3 ? "active" : ""}>
                      <span className="circle"></span>
                      <span>Other Settings</span>
                    </li>
                  </ul>
                  <div className="createoffer__content">
                    {activeState === 1 && (
                      <div className="cryptocurrency">
                        {
                          !loading && tokens.length >= 2 ?
                            <>
                              <div className="cryptocurrency__selectcoin">
                                <h4>I want to Sell</h4>
                                <SelectionBox
                                  data={tokens}
                                  currentData={sellCoinType}
                                  changeHandler={handleBuyCoinType}
                                  alwaysOpen
                                />
                              </div>
                              <div className="cryptocurrency__selectcoin">
                                <h4>I want to receive</h4>
                                <SelectionBox
                                  data={tokens}
                                  currentData={payCoinType}
                                  changeHandler={handlePayCoinType}
                                  alwaysOpen
                                />
                              </div>
                            </> :
                            <>
                              {
                                !loading &&
                                <h4> Trade can't made with {tokens.length} Token </h4>
                              }
                            </>
                        }
                        {
                          loading ?
                            <>
                              <div className="text-center">
                                <Loading width={40} />
                                <p>Loading...</p>
                              </div>
                            </> : ''
                        }
                        <Link to={"/addToken"} >
                          <button style={{ marginTop: "30px" }} className=" btn btn--outline" type="submit"> Create A New Token </button>
                        </Link>
                      </div>
                    )}
                    {activeState === 2 && (
                      <div className="pricing">
                        <h4 className="pricing__title">Trade Pricing</h4>
                        <h4>Choose Ethereum rate you want to use</h4>
                        <div className="pricing__selectrates">

                          <div
                            className={`pricing__selectrate ${activeRate === "regularprice" ? "active" : ""
                              }`}
                            onClick={() => setActiveRate("regularprice")}
                          >
                            <span className="circle"></span>
                            <div>
                              <h5>Fixed Price</h5>
                              <p>
                                Your offer’s selling price will change according to
                                the market price of Eth.
                              </p>
                            </div>
                          </div>
                          <div
                            className={`pricing__selectrate ${activeRate === "marketprice" ? "active" : ""
                              }`}
                            onClick={() => setActiveRate("marketprice")}
                          >
                            <span className="circle"></span>
                            <div>
                              <h5>Market Price</h5>
                              <p>
                                Your offer’s selling price will change according to
                                the market price of Eth.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="pricing__section pricing__minmax">
                          <div className="pricing__section-left" style={{ marginTop: '30px' }}>
                            <h4>Price [Per Token {payCoinType.symbol} ] </h4>
                          </div>
                          <div className="pricing__section-right">
                            <div>
                              <label htmlFor="">Price [Per Token {payCoinType.symbol} ]  </label>
                              <div className="inputbox">
                                <input type="number" value={price} min={0} placeholder="Enter Amount" onChange={(e) => handlePriceChange(e)} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pricing__section pricing__minmax">
                          <div className="pricing__section-left">
                            <button className="btn btn--gradient">
                              Use Fixed Amount
                            </button>
                          </div>
                          <div className="pricing__section-right">
                            <div>
                              <label htmlFor="">Minimum</label>
                              <div className="inputbox">
                                <input onChange={e => setMinAmount(parseInt(e.target.value))} value={minAmount} type="number" placeholder="10" />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="">Maximum</label>
                              <div className="inputbox">
                                <input onChange={e => setMaxAmount(parseInt(e.target.value))} value={maxAmount} type="number" placeholder="100" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pricing__section">
                          <div className="pricing__section-left" style={{ marginTop: '30px' }}>
                            <h4>Offer Trade Limits</h4>
                          </div>
                          <div className="pricing__section-right">
                            <label htmlFor="">One each trade</label>
                            <div className="quantitybox">
                              <span
                                className="dec"
                                onClick={(e) =>
                                  handleOfferTradeLimitChange(
                                    e,
                                    offerTradeLimit - 1
                                  )
                                }
                              >
                                -
                              </span>
                              <input
                                type="text"
                                value={offerTradeLimit}
                                onChange={(e) =>
                                  handleOfferTradeLimitChange(
                                    e,
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                              <span
                                className="inc"
                                onClick={(e) =>
                                  handleOfferTradeLimitChange(
                                    e,
                                    offerTradeLimit + 1
                                  )
                                }
                              >
                                +
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="pricing__section">
                          <div className="pricing__section-left" style={{ marginTop: '30px' }}>
                            <h4>Payment Time Limit </h4>
                          </div>
                          <div className="pricing__section-right">
                            <label htmlFor="">One each trade</label>
                            <div className="quantitybox">
                              <span
                                className="dec"
                                onClick={(e) =>
                                  handleOfferTimeLimitChange(e, offerTimeLimit - 1)
                                }
                              >
                                -
                              </span>
                              <input
                                type="text"
                                value={offerTimeLimit}
                                onChange={(e) =>
                                  handleOfferTimeLimitChange(
                                    e,
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                              <span
                                className="inc"
                                onClick={(e) =>
                                  handleOfferTimeLimitChange(e, offerTimeLimit + 1)
                                }
                              >
                                +
                              </span>
                            </div>
                            <br />
                            <p>
                              This is how much time your trade partner has to make
                              the payment and click Paid before the trade is
                              automatically canceled.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeState === 3 && (
                      <div className="othersetting">
                        <h4 className="othersetting__title">Trade Pricing</h4>
                        <h4>Your Offer Label</h4>
                        <div className="inputbox">
                          <input
                            type="text"
                            placeholder="Maximum 25 characters, Only letters, numbers and dashes"
                            onChange={e => setOfferLabel((e.target.value))}
                          />
                        </div>
                        <br />
                        <p>
                          Make your offer stand out to other users with a catchy
                          label. Your offer label can be up to 25 characters long
                          and can contain letters, numbers, the apostrophe and the
                          hypen.
                        </p>
                        <br />
                        {/* <h4>Offer Terms</h4>
                        <div className="inputbox">
                          <textarea
                            onChange={e => setOfferTerms(e.target.value)}
                            name=""
                            id=""
                            placeholder="Write your terms here..."
                          ></textarea>
                        </div> */}

                        <h4>Reason for Table</h4>
                        <ul>
                          <li>
                            <input
                            id="reduce"
                              type="checkbox"
                              checked={selectedOptions.includes('Reduce Single Position Risk')}
                              onChange={() => handleOptionToggle('Reduce Single Position Risk')}
                            />
                            <label htmlFor="reduce">Reduce Single Position Risk</label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              checked={selectedOptions.includes('Unexpected Liquidity Need')}
                              onChange={() => handleOptionToggle('Unexpected Liquidity Need')}
                              id="Unexpected"
                            />
                            <label htmlFor="Unexpected">Unexpected Liquidity Need</label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              checked={selectedOptions.includes('Reduce Market Impact From Large Scale')}
                              onChange={() => handleOptionToggle('Reduce Market Impact From Large Scale')}
                              id="ReduceMarket"
                            />
                           <label htmlFor="ReduceMarket"> Reduce Market Impact From Large Scale</label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              checked={selectedOptions.includes('Disclose to Answer')}
                              onChange={() => handleOptionToggle('Disclose to Answer')}
                              id="disclose"
                            />
                           <label htmlFor="disclose"> Disclose to Answer</label>
                          </li>
                        </ul>
                        <br />
                        <p>
                          Anybody who vies your offers will see these terms. Keep
                          them simple and clear to make your offer sound attractive.
                        </p>
                        <br />
                        {/* <h4>Trade Instructions</h4>
                        <div className="inputbox">
                          <textarea
                            onChange={e => setOfferInstruction(e.target.value)}
                            name=""
                            id=""
                            placeholder="Write your terms here..."
                          ></textarea>
                        </div> */}
                        <h4>Trade Instructions</h4>
                        <ul>
                          <li> <input type="radio" id="anyamountallowd" name="amount" onChange={e=>setSplitedTrade(true)} checked /> <label htmlFor="anyamountallowd">Any Amount Allowed</label></li>
                          <li> <input type="radio" id="anyamountNont" name="amount" onChange={e=>setSplitedTrade(true)} /> <label htmlFor="anyamountNont">Allow or None</label></li>
                        </ul>
                        <br />
                        <p>
                          To ensure a successful trade be transparent about what you
                          expect from your trade partner and list our what you need.
                        </p>
                      </div>
                    )}
                  </div>
                </>
              }
            </div>

            <div className="createoffer__right">
              {
                offerCreationStatus == 0 &&
                <h3>About This Step</h3>
              }
              {offerCreationStatus == 0 && activeState === 1 && (
                <>
                  <p>
                    Start creating your offer by selecting the cryptocurrency
                    what you have and what you want to get paid with.
                  </p>
                  <ul>
                    <li>You want to Sell {sellCoinType.name ? sellCoinType.name : " Selecting..."} </li>
                    <li>And get paid in {payCoinType.name ? payCoinType.name : " Selecting..."}  </li>
                    <li>You will pay NOM a 1% fee for each trade</li>
                  </ul>
                </>
              )}
              {offerCreationStatus == 0 && activeState === 2 && (
                <>
                  <p>
                    Start creating your offer by selecting the cryptocurrency
                    what you have and what you want to get paid with.
                  </p>
                  <ul>
                    <li>You want to Sell {sellCoinType.name} </li>
                    <li>And get paid in {payCoinType.name}  </li>
                    <li>You will pay NOM a 1% fee for each trade</li>
                    <li>You will make a profit of 5% on every trade</li>
                    <li>People can trade between 10 and 100</li>
                    <li>You are Selling {maxAmount * price} {sellCoinType.name} for  {maxAmount} {payCoinType.name}  </li>
                  </ul>
                </>
              )}
              {offerCreationStatus == 0 && activeState === 3 && (
                <>
                  <p>
                    Set the terms, label and limirations for people to trade on
                    this offer.
                  </p>
                  <ul>
                    <li>You want to Sell {sellCoinType.name}  </li>
                    <li>And get paid in {payCoinType.name}  </li>
                    <li>You will pay NOM a 1% fee for each trade</li>
                    <li>You will make a profit of 5% on every trade</li>
                    <li>People can trade between 10 and 100</li>
                  </ul>
                </>
              )}
              <div className="createoffer__prevnext">
                {
                  offerCreationStatus == 0 &&
                  <>
                    {
                      (offerCreationStatus == 0) && <button className="btn btn--gradient" onClick={handlePrev}>Previous</button>
                    }
                    {activeState !== 3 ? (
                      <button className="btn" onClick={handleNext}>Next</button>
                    ) : (
                      <> {
                        (offerCreationStatus == 0) && <button className="btn" onClick={handleSubmit}>Create offer</button>
                      }
                      </>
                    )}
                  </>
                }

                {
                  (offerCreationStatus == 1) &&
                  <div className="text-center">
                    <div>
                      <img width={"40px"} src="/images/loading.gif" />
                    </div>
                    <p style={{ marginTop: '10px' }} >Offer Creation in Pending ...</p>
                  </div>
                }
                {
                  (offerCreationStatus == 2) && <p style={{ marginTop: '50px' }}>Offer Successfully Created<Link to={'/offers'} style={{ textDecoration: 'underline', fontWeight: 500, color: 'white' }}>Find Offer</Link> </p>
                }
                {
                  (offerCreationStatus == 3) && <div>
                    <h4>Offer Creation Failed</h4>
                    <p> [{offerCreationError.message}] </p>
                    <div>
                      <p> 🙋 Help :</p>
                      <ul>
                        {
                          offerCreationError.help?.map((err, i) => (
                            <li key={err} > {err} </li>
                          ))
                        }
                      </ul>
                      <p>Still need help ?  <a href="#" style={{ color: "gray", textDecoration: 'underline' }} >Contact Us</a> </p>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default Createoffer;
