import React, { useState, SyntheticEvent, useEffect } from "react";
import { 
  FiArrowUp, 
  FiThumbsDown,
  FiThumbsUp,
} from "react-icons/fi";
import SiteLayout from "../components/layout/SiteLayout";
import { useRecoilState } from "recoil"
import { authAppState, userState } from "../state/state";
import axios from "axios";
import { baseURL, getLastActiveTime } from "../util/utils";
import authHeader from "../services/auth-header";
import Loading from "../components/loading";
import { BiFileBlank } from 'react-icons/bi'
import { Link, useNavigate } from "react-router-dom";
import moment from "moment"; 




type TOfferTab = "buy" | "sell";
type TFeedbackTab = "buyers" | "sellers";

const ProfilePage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useRecoilState(userState)
  const [activeOfferType, setActiveOfferType] = useState<TOfferTab>("sell");
  const [activeSellOffers, setActiveSellOffers] = useState([])
  const [activeBuyOffers, setActiveBuyOffers] = useState([])
  const [dataPerTable, setDataPerTable] = useState(10)
  const [feedbacFromCryptoBuyers, setFeedbacFromCryptoBuyers] = useState<any>([])
  const [feedbacFromCryptoSeller, setFeedbacFromCryptoSeller] = useState<any>([])
  const [buyFeedbackRowCount, setBuyFeedbackRowCount] = useState(5)
  const [sellFeedbackRowCount, setSellFeedbackRowCount] = useState(5)



  const loadMoreFeedback = () => {
    if (activeFeedbackType == "buyers") {
      setBuyFeedbackRowCount(buyFeedbackRowCount + 1)
    } else {
      setSellFeedbackRowCount(buyFeedbackRowCount + 1)
    }
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      var sellOffer = await axios.get(`${baseURL}/api/offers/activeSellOffers`, { headers: authHeader() })
      setActiveSellOffers(sellOffer.data)
      var buyOffer = await axios.get(`${baseURL}/api/offers/activeBuyOffers`, { headers: authHeader() })
      setActiveBuyOffers(buyOffer.data)
      var myFeedback = await axios.get(`${baseURL}/api/feedback/my-feedback`, { headers: authHeader() })
      setFeedbacFromCryptoBuyers(myFeedback.data.ffcb)
      setFeedbacFromCryptoSeller(myFeedback.data.ffcs)
    }
    fetchData()
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])
  const [activeFeedbackType, setActiveFeedbackType] =
    useState<TFeedbackTab>("sellers");

  const handleActiveOfferType = (e: SyntheticEvent, value: TOfferTab) => {
    e.preventDefault();
    setActiveOfferType(value);
  };

  const handleActiveFeedbackType = (e: SyntheticEvent, value: TFeedbackTab) => {
    e.preventDefault();
    setActiveFeedbackType(value);
  };
  const getOfferStatus = (status: any) => { 
    if (status == 1) return (<span className="tag tag--success">New Offer</span>)
    if (status == 2) return (<span className="tag tag--danger">Pending</span>)
    if (status == 3) return (<span className="tag tag--warning">Completed</span>)
    else return (<span className="tag tag--success">New Offer</span>)
  }
  return (
    <SiteLayout variant="withoptions">
      <div className="profile">
        <div className="container">
          <div className="profile__inner">
            <div
                // onClick={e=> copyText(user._id, "User ID Copied !!") }
             className="userprofile cp">
              <img
                src={`${baseURL}/${user.pp}`}
                alt=""
                className="userprofile__image cp"
              />
              <div className="userprofile__content">
                <h2 className="userprofile__name">{user.name}</h2>
                <p className="userprofile__lastseen">  <span> {getLastActiveTime(user.lastSeen)} </span> </p>

                <div className="userprofile__feedbacks">
                  <div className="userprofile__feedback">
                    <div className="userprofile__feedback-icon">
                      <FiThumbsUp />
                    </div>
                    <h2>+ {user.upVote} </h2>
                    <p>Positive feedback</p>
                  </div>
                  <div className="userprofile__feedback">
                    <div className="userprofile__feedback-icon">
                      <FiThumbsDown />
                    </div>
                    <h2>-{user.downVote} </h2>
                    <p>Negative feedback</p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <h4>Active Offers</h4>
            <p> <Link to='/tradehistory'>Check latest transection and trade history <span style={{ textDecoration: 'underline', color: '#EE3571' }}> here</span> </Link> </p>
            <div className="profileoffers">
              <div className="tabstyles">
                <button
                  className={activeOfferType === "sell" ? "active" : ""}
                  onClick={(e) => handleActiveOfferType(e, "sell")}
                >
                  Sell
                </button>
                <button
                  className={activeOfferType === "buy" ? "active" : ""}
                  onClick={(e) => handleActiveOfferType(e, "buy")}
                >
                  Buy
                </button>
              </div>
              <div className="profileoffers__offers">
                <div className="table-responsive noborder">
                  <table className="table" border={0}>
                    <thead>
                      <tr>
                        <th className="text-left">Buy from</th>
                        <th className="text-center">Pay with</th>
                        <th className="text-center">Avg. trade speed</th>
                        <th className="text-left">Purchase  {activeOfferType == "buy" ? "Amount" : "Limit"} </th>
                        <th className="text-left">Price per Tether</th>
                        <th className="text-right">
                          {/* <FiSliders /> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ?
                          <>
                            <tr>
                              <td colSpan={6} >
                                <div className="text-center" >
                                  <Loading width={30} />
                                  <p> Loading...  </p>
                                </div>
                              </td>
                            </tr>
                          </> : ''
                      }
                      {
                        !loading && activeOfferType == "sell" ?
                          <>

                            {
                              !loading && activeSellOffers.length < 1 ?
                                <tr>
                                  <td colSpan={6}>
                                    <div className="text-center">
                                      <BiFileBlank size={30} />
                                      <h5>No offer Exist !</h5>
                                    </div>
                                  </td>
                                </tr>
                                : ''
                            }
                            {
                              activeSellOffers.map((offer: any,i:any) => {
                                return (
                                  <React.Fragment key={i}>
                                    {
                                      (offer.soldRecord).map((order: any, i: any) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-left">
                                              <div className="buyfrom">
                                                <div className="image">
                                                  <img
                                                    src={`${baseURL}/${user.pp}`} alt="img"
                                                  />
                                                </div>
                                                <div className="content">
                                                  <small> {offer.user?.name} </small>
                                                  <div className="likes">
                                                    <FiThumbsUp /> <small> {offer.user.upVote} </small>
                                                  </div>
                                                  <span className="tiny-text">
                                                     {getLastActiveTime(offer.user.lastSeen)}  
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="paywith">
                                                <div className="image">
                                                  <img src="/icons/ethereum.svg" alt="ethereum" />
                                                </div>
                                                <div className="content">
                                                  <small> {offer.buyToken?.chain} </small>
                                                  <span className="tiny-text">
                                                    Instant Confirmation
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div>
                                                {getOfferStatus(order?.status)}
                                              </div>
                                            </td>
                                            <td className="text-left">
                                              <div className="minmax">
                                                <small>Min purchase: {offer.minAmount} {offer.buyToken?.symbol}  </small>
                                                <small>Max purchase: {offer.maxAmount} {offer.buyToken?.symbol}  </small>
                                              </div>
                                            </td>
                                            <td className="text-left">
                                              <div className="perprice">
                                                <p className="font-semibold">0.03Eth</p>
                                                <h6 className="success">
                                                  <FiArrowUp />
                                                  <small> 0.11%</small>
                                                </h6>
                                              </div>
                                            </td>
                                            <td className="text-right">
                                              <div>
                                                <button onClick={() => { navigate('/tradestart?offerID=' + offer._id + "&index=" + i, { replace: true }) }} className="btn btn--gradient btn--extrasmall">
                                                  Details
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </React.Fragment>
                                )
                              })
                            }
                          </> :
                          <>
                            {
                              !loading && activeBuyOffers.length < 1 ?
                                <tr>
                                  <td colSpan={6}>
                                    <div className="text-center">
                                      <BiFileBlank size={30} />
                                      <h5>No offer Exist !</h5>
                                    </div>
                                  </td>
                                </tr>
                                : ''
                            }
                            {
                              activeBuyOffers.map((offer: any,i:any) => {
                                return (
                                  <React.Fragment key={i}>

                                    {
                                      offer.soldRecord.map((order: any, i: any) => {
                                        return (
                                          <tr>
                                            {
                                              (order.userID == user._id) &&
                                              <>
                                                <td className="text-left">
                                                  <div className="buyfrom">
                                                    <div className="image">
                                                      <img
                                                        src={`${baseURL}/${offer.user.pp}`} alt="img"
                                                      />
                                                    </div>
                                                    <div className="content">
                                                      <small> {offer.user?.name} </small>
                                                      <div className="likes">
                                                        <FiThumbsUp /> <small> {offer.user.upVote} </small>
                                                      </div>
                                                      <span className="tiny-text">
                                                          {getLastActiveTime(offer.user.lastSeen)}  
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="text-center">
                                                  <div className="paywith">
                                                    <div className="image">
                                                      <img src="/icons/ethereum.svg" alt="ethereum" />
                                                    </div>
                                                    <div className="content">
                                                      <small> {offer.buyToken?.chain} </small>
                                                      <span className="tiny-text">
                                                        Instant Confirmation
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="text-center">
                                                  <div>
                                                    {getOfferStatus(order.status)}
                                                  </div>
                                                </td>
                                                <td className="text-left">
                                                  <div className="minmax">
                                                    <small>Purchase : {order.amount} {offer.buyToken?.symbol}  </small>
                                                  </div>
                                                </td>
                                                <td className="text-left">
                                                  <div className="perprice">
                                                    <p className="font-semibold">0.03Eth</p>
                                                    <h6 className="success">
                                                      <FiArrowUp />
                                                      <small> 0.11%</small>
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td className="text-right">
                                                  <div>
                                                    <button onClick={() => { navigate('/tradestart?offerID=' + offer._id + "&index=" + i, { replace: true }) }} className="btn btn--gradient btn--extrasmall">
                                                      Details
                                                    </button>
                                                  </div>
                                                </td>
                                              </>
                                            }
                                          </tr>
                                        )
                                      })
                                    }
                                  </React.Fragment>
                                )
                              })
                            }
                          </>
                      }
                    </tbody>
                  </table>
                </div>
                {
                  !loading && <>
                    {
                      activeOfferType == "sell" ?
                        <>
                          {
                            activeSellOffers.length > dataPerTable ?
                              <div className="profileoffers__showmore">
                                <button className="btn">Show More</button>
                              </div> : ''
                          }
                        </> :
                        <>
                          {
                            activeBuyOffers.length > dataPerTable ?
                              <div className="profileoffers__showmore">
                                <button className="btn">Show More</button>
                              </div> : ''
                          }
                        </>
                    }
                  </>
                }
                {/* <h6 className="nooffers">No Active Offers</h6> */}
              </div>
            </div>
            <br />

            <h4>Feedback</h4>
            <div className="profilefeedback">
              <div className="tabstyles">
                <button
                  className={activeFeedbackType === "buyers" ? "active" : ""}
                  onClick={(e) => handleActiveFeedbackType(e, "buyers")}
                >
                  From Crypto Buyers
                </button>
                <button
                  className={activeFeedbackType === "sellers" ? "active" : ""}
                  onClick={(e) => handleActiveFeedbackType(e, "sellers")}
                >
                  From Crypto Sellers
                </button>
              </div>
              <div className="profilefeedback__feedback">
                {
                  activeFeedbackType == "buyers" ?
                    <>
                      {
                        (feedbacFromCryptoBuyers.slice(0, buyFeedbackRowCount)).map((feedback: any,i:any) => (
                          <React.Fragment key={i}>
                            {
                              feedback?.buyerFeedback.done &&
                              <div className="feedback">
                                <img
                                  src={`${baseURL}/${feedback.buyerID.pp}`}
                                  alt=""
                                  className="feedback__image"
                                />
                                <div className="feedback__usercontent">
                                  <p> {feedback.buyerID.name}  </p>
                                  <small>{(moment(feedback?.createdAt)).format("MMM DD, YYYY")}  </small>
                                  {
                                    feedback.buyerFeedback.vote == 1 ?
                                      <div className="feedback__type">
                                        <FiThumbsUp /> Positive
                                      </div> :
                                      <div className="feedback__type">
                                        <FiThumbsDown /> Negative
                                      </div>
                                  }
                                </div>
                                <p className="feedback__content">
                                  {feedback.buyerFeedback.feedback}
                                </p>
                              </div>
                            }
                          </React.Fragment>
                        ))
                      }
                    </> :
                    <>
                      {
                        (feedbacFromCryptoSeller.slice(0, sellFeedbackRowCount)).map((feedback: any, i:any) => (
                          <React.Fragment key={i}>
                            {
                              feedback?.sellerFeedback.done &&
                              <div className="feedback">
                                <img
                                  src={`${baseURL}/${feedback.sellerID.pp}`}
                                  alt=""
                                  className="feedback__image"
                                />
                                <div className="feedback__usercontent">
                                  <p> {feedback.sellerID.name} </p>
                                  <small>{(moment(feedback?.createdAt)).format("MMM DD, YYYY")}  </small>
                                  {
                                    feedback.sellerFeedback.vote == 1 ?
                                      <div className="feedback__type">
                                        <FiThumbsUp /> Positive
                                      </div> :
                                      <div className="feedback__type">
                                        <FiThumbsDown /> Negative
                                      </div>
                                  }
                                </div>
                                <p className="feedback__content">
                                  {feedback.sellerFeedback.feedback}
                                </p>
                              </div>
                            }
                          </React.Fragment>
                        ))
                      }
                    </>
                }
                <div className="profileoffers__showmore">
                  <button className="btn" onClick={e => loadMoreFeedback()} >Show More</button>
                </div>
                {/* <h6 className="nooffers">No Active Feedback</h6> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default ProfilePage;
