import { useState, useEffect } from "react";
import { FiMail } from "react-icons/fi";
import { DiCode } from "react-icons/di";
import SiteLayout from "../components/layout/SiteLayout";
import RegisterImage from "../assets/images/register-image.png";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../util/utils";
import Loading from "../components/loading";
import queryString from 'query-string'
import { Link } from "react-router-dom";
const Varify: React.FC = () => {

  const [email, setEmail] = useState<string>()
  const [OTP, setOTP] = useState<number>()
  const [isVarifying, setIsVarifying] = useState(false)

  useEffect(() => {
    var params = queryString.parse(window.location.search)
    if (params.user) {
      setEmail(`${params.user}`)
    }
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsVarifying(true)
    axios.post(`${baseURL}/api/users/varify`, { OTP: OTP, email: email })
      .then((response) => {
        toast.success(response.data?.message, { autoClose: 1500, position: 'top-center' });
        setIsVarifying(false)
        setTimeout(() => {
          window.location.href = "/login"
        }, 1500);
      },
        error => {
          setIsVarifying(false)
          toast.error(error.response?.data.message, { autoClose: 1500 });
        }
      );
  }

  return (
    <SiteLayout variant="onlylogo" noFooter>
      <div className="register">
        <div className="container">
          <div className="register__inner">
            <div className="register__content">
              <h4 className="register__title">Enter Your email and Verification Code</h4>
              <form className="register__form" method="post" onSubmit={(e) => handleSubmit(e)}>
                <div className="register__field">
                  <label htmlFor="">Your E-mail</label>
                  <div className="inputbox">
                    <FiMail />
                    <input type="email" value={email} required placeholder="Enter user e-mail" name="email" onChange={e => setEmail(e.target.value)} />
                  </div>
                </div>
                <div className="register__field">
                  <label htmlFor="">OTP Code <small> [ Tip: Find on your email ] </small> </label>
                  <div className="inputbox">
                    <DiCode />
                    <input type="number" required placeholder="Enter OTP here " name="OTP" onChange={e => setOTP(parseInt(e.target.value))} />
                  </div>
                </div>
                <div className="register__buttons">
                  {
                    isVarifying ?
                      <div className="text-center">
                        <Loading width={30} />
                        <p>Please Wait Verifying...</p>
                      </div> :
                      <button className="btn " style={{ border: '1px solid dashed ' }} type="submit">
                        Verify
                      </button>
                  }

                  <Link to={'/'}>
                    <button
                      className="btn btn--outline"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
            <div className="register__image">
              <img src={RegisterImage} alt="register" />
            </div>
          </div>
        </div>
        <span className="blobbg register__blob"></span>
      </div>
    </SiteLayout>
  );
};

export default Varify;
