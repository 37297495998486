import SiteLayout from "../components/layout/SiteLayout";
import CallToAction from "../components/sections/CallToAction";
import Herosection from "../components/sections/Herosection";
import Statistics from "../components/sections/Statistics";
import Testimonials from "../components/sections/Testimonials";

const Homepage = () => {
  return (
    <SiteLayout>
      <Herosection />
      <Statistics />
      <Testimonials />
      <CallToAction />
    </SiteLayout>
  );
};

export default Homepage;
