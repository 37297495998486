import { SyntheticEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import siteLogo from "../../assets/images/sitelogo.png";
import siteLogoMobile from "../../assets/images/sitelogo-mobile.png";
import {
  FiBell,
  FiChevronDown,
  FiLogIn,
  FiLogOut,
  FiMail,
  FiMenu,
  FiSettings,
  FiX,
} from "react-icons/fi";
import { RiHistoryLine } from "react-icons/ri";
import { IUser } from "../../type";
import { appState, authAppState, userState } from "../../state/state";
import { useRecoilState } from 'recoil'
import { baseURL, isAuthenticated } from "../../util/utils";
import { toast } from "react-toastify";
import { NOTIFICATION_STATUS } from "../../util/constant";

type Props = {
  user: IUser,
}

const SiteHeader: React.FC<Props> = () => {
  const [user, setUser] = useRecoilState(userState)
  const [getAuthAppState, setAuthAppState] = useRecoilState(authAppState)
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [mobileHeader, setMobileHeader] = useState<boolean>(false);


  // Sticky Header
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      });
    }
    return () => {
      window.removeEventListener("scroll", () => { });
    };
  });

  const copyText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(`Copied "${text}" to clipboard`);
    } catch (error) {
      console.error('Failed to copy to clipboard: ', error);
    }
  };
  const handleLogout = (e: SyntheticEvent) => {
    e.preventDefault();
    window.localStorage.removeItem("user")
    toast.success("Successfully Logged out", { autoClose: 1500, position: 'top-center' })
    setTimeout(() => {
      return window.location.href = "/login"
    }, 1500);
  };

  return (
    <header
      className={`header header--alt ${isSticky ? "header--sticky" : ""}`}
    >
      <div className="container">
        <div className="header__inner">
          <Link to="/" className="header__logo">
            <img src={siteLogo} alt="NOM" />
            <img
              src={siteLogoMobile}
              alt="NOM"
              className="mobilelogo"
            />
          </Link>
          <nav className={`header__menu ${mobileHeader ? "visible" : ""}`}>
            <button className="close" onClick={() => setMobileHeader(false)}>
              <FiX />
            </button>
          </nav>
          {
            isAuthenticated() ?
              <div className="header__useroptions">
                <ul style={{
                  margin: " 0",
                  padding: "0",
                  listStyle: "none",
                  marginRight: "50px"
                }} >
                  <li>
                    <Link to="/createoffer">Create Offer</Link>
                  </li>
                </ul>
                <div className="header__user">
                  <Link to="/profile" className="header__usertoggle">
                    <div>
                      <span>{user.name}</span>
                      <span>{user.publicKey.substring(0, 6) + "..."}</span>
                    </div>
                    <img
                      src={`${baseURL}/${user.pp}`}
                      alt="#"
                    />
                  </Link>
                  <div className="header__usermenu">
                    <h6>
                      <Link to={"/profile"}>
                        My Profile
                      </Link>
                    </h6>
                    <p>
                      <span className="d_none copyaddress" > {user.publicKey}   </span>
                      <span className="cp" onClick={e => { toast.success("Wallet address Copied", { autoClose: 600 }); copyText(user.publicKey) }} >{user.publicKey.substring(0, 10) + "..."}</span>
                    </p>

                    <ul>
                      <li>
                        <Link to="/accountsetting">
                          <FiSettings /> Settings
                        </Link>
                      </li>
                      <li>
                        <Link to="/tradehistory">
                          <RiHistoryLine /> Trade History
                        </Link>
                      </li>
                      <li>
                        <button onClick={(e) => handleLogout(e)}>
                          <FiLogOut /> Log Out
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> :
              <ul style={{
                margin: " 0",
                padding: "0",
                listStyle: "none",
                marginRight: "50px"
              }} >
                <li>
                  <Link to="/createoffer">Create Offer</Link>
                </li>
              </ul>
          }
          <button
            className="btn header__menutoggler"
            onClick={() => setMobileHeader(!mobileHeader)}
          >
            <FiMenu />
          </button>
        </div>
      </div>
    </header >
  );
};

export default SiteHeader;
